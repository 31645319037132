import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { client } from '../axiosClient';
import { Button } from './ui/button';
import { Card } from './ui/card.jsx';
import OutfitUpload from './OutfitUpload';
import poorOutfit from '../assets/poorOutfit.webp';
import richOutfit from '../assets/richOutfit.webp';
import etek from '../assets/etek.webp';
import kazak from '../assets/kazak.webp';
import bag from '../assets/bag.webp';
import shoes from '../assets/shoes.webp';
import demoTop from '../assets/demoTop.webp';
import demoTopNoBg from '../assets/demoTopNoBg.webp';
import demoShoes from '../assets/demoShoes.webp';
import demoShoeNoBg from '../assets/demoShoe.webp';
import ReactMarkdown from 'react-markdown';
import { toast } from './ui/use-toast';

const WelcomeOnboarding = ({ onComplete }) => {
  const [step, setStep] = useState('choice'); // 'choice', 'upload', 'demo', 'complete'
  const [uploadType, setUploadType] = useState(null); // 'top' or 'bottom'
  const [isGenerating, setIsGenerating] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [demoImage, setDemoImage] = useState(null);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [hasGeneratedSuggestion, setHasGeneratedSuggestion] = useState(false);
  const [hasAnalyzedImage, setHasAnalyzedImage] = useState(false);
  const [isCustomUpload, setIsCustomUpload] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  // Add tracking IDs for each step
  const getStepId = (step) => `onboarding_step_${step}`;
  
  // Add tracking IDs for navigation
  const getNavigationId = (direction, fromStep) => `onboarding_${direction}_from_${fromStep}`;

  // Add tracking IDs for actions
  const getActionId = (action, step) => `onboarding_${action}_at_${step}`;

  const handleAnalyzeImage = async (file) => {
    if (hasAnalyzedImage) {
      toast({
        title: "Demo Already Viewed",
        description: "You've already analyzed an image. Continue to try the full features!",
        variant: "default"
      });
      return;
    }
    setIsCustomUpload(true);
    setIsAnalyzing(true);
    try {
      const formData = new FormData();
      formData.append('image', file);
      const response = await client.post('/api/analyze-demo-image/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setAnalysisResult(response.data);
      setHasAnalyzedImage(true);
    } catch (error) {
      console.error('Error analyzing image:', error);
      toast({
        title: "Analysis Failed",
        description: "There was an error analyzing the image. Please try again.",
        variant: "destructive"
      });
    } finally {
      setIsAnalyzing(false);
    }
  };

  // Mock analysis results for demo images
  const mockAnalysisResults = {
    'demo-top': {
      category: 'top',
      color: 'mustard yellow ',
      seasons: ['spring', 'summer'],
      activities: ['casual', 'day out', 'brunch'],
      detailed_description: 'The cropped length with a cinched waist creates a flattering, shapely form. The gathered, elasticized hem and drawstring add adjustability. The off-shoulder, ruffled neckline paired with voluminous puff sleeves conveys a vintage-inspired, peasant-style look. Overall, this design blends casual elegance with a touch of retro charm, making it versatile for various fashion styles like boho-chic or cottagecore.',
    },
    'demo-shoes': {
      category: 'shoes',
      color: 'Deep burgundy',
      seasons: ['spring', 'summer', 'fall'],
      activities: ['casual', 'formal', 'office'],
      detailed_description: 'These boots are a masterful blend of classic craftsmanship and contemporary edge. The deep burgundy leather exudes sophistication, while the contrasting suede upper adds depth and texture, creating a dynamic interplay of materials. The precise stitching along the sole not only enhances durability but also elevates the artisanal appeal.',
    }
  };

  const handleAnalyzeDemoImage = async (demoId) => {
    if (hasAnalyzedImage) {
      toast({
        title: "Demo Already Viewed",
        description: "You've already analyzed an image. Continue to try the full features!",
        variant: "default"
      });
      return;
    }
    setIsCustomUpload(false);
    setIsAnalyzing(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 1500));
      // Use demoTopNoBg for top and demoShoeNoBg for shoes
      const demoImage = demoId === 'demo-top' ? demoTopNoBg : demoShoeNoBg;
      const response = await fetch(demoImage);
      const blob = await response.blob();
      const file = new File([blob], `${demoId}.webp`, { type: 'image/webp' });
      setDemoImage(file);
      setAnalysisResult(mockAnalysisResults[demoId]);
      setHasAnalyzedImage(true);
    } catch (error) {
      console.error('Error loading demo image:', error);
      toast({
        title: "Demo Load Failed",
        description: "There was an error loading the demo. Please try again.",
        variant: "destructive"
      });
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleComplete = async () => {
    try {
      await client.patch('/api/profile/update/', { has_completed_onboarding: true });
      if (onComplete) {
        onComplete();
      }
      console.log('Navigating to pricing page...');
      navigate('/pricing');
    } catch (error) {
      console.error('Error completing onboarding:', error);
    }
  };

  const handleUploadComplete = () => {
    setStep('complete');
  };

  const mockSuggestion = {
    suggestion: "This outfit strikes the perfect balance between comfort and style. The cozy rose-gold knit sweater adds warmth and texture, while the black pleated skirt keeps it playful and chic. Paired with elegant beige heels for a touch of sophistication and a metallic bag for a trendy accent, this look is ideal for catching up with friends over coffee, brunch, or a casual day out",
    suggested_outfits: [
      {
        id: 1,
        image_url: kazak,
        category: "top"
      },
      {
        id: 2,
        image_url: etek,
        category: "bottom"
      },
      {
        id: 3,
        image_url: shoes,
        category: "shoes"
      },
      {
        id: 4,
        image_url: bag,
        category: "bag"
      }
    ]
  };

  // Back button component
  const BackButton = ({ onClick, id }) => (
    <button
      id={id}
      onClick={onClick}
      className="absolute top-6 left-6 text-gray-600 hover:text-gray-900 flex items-center gap-2 transition-colors"
    >
      <svg 
        id={id}
        className="w-5 h-5" 
        fill="none" 
        stroke="currentColor" 
        viewBox="0 0 24 24"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
      </svg>
      <span id={id}>Back</span>
    </button>
  );

  if (step === 'choice') {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-50 to-purple-50 flex items-center justify-center p-4">
        <Card className="max-w-2xl w-full p-8 space-y-8" id={getStepId('choice')}>
          <h1 className="text-3xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">Welcome to StyleGen AI</h1>
          <p className="text-lg text-center text-gray-600">
            Your AI-Powered Personal Stylist for Effortless Fashion
          </p>
          <div className="space-y-8">
            <div className="grid grid-cols-2 gap-4">
              <div className="text-center">
                <h3 className="text-lg font-semibold mb-2">Before</h3>
                <img
                  id="before-image"
                  src={poorOutfit}
                  alt="Before transformation"
                  className="w-full h-64 object-cover rounded-lg scale-95"
                />
              </div>
              <div className="text-center">
                <h3 className="text-lg font-semibold mb-2">After</h3>
                <img
                  id="after-image"
                  src={richOutfit}
                  alt="After transformation"
                  className="w-full h-64 object-cover rounded-lg transform scale-105 transition duration-300"
                />
              </div>
            </div>
            <Button
              onClick={() => setStep('demo')}
              className="block mx-auto w-auto text-md bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-full hover:from-purple-500 hover:to-blue-600 transition-all duration-300 shadow-md hover:shadow-lg"
              id={getNavigationId('next', 'choice')}
            >
              See in Action
            </Button>
          </div>
        </Card>
      </div>
    );
  }

  if (step === 'upload') {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-50 to-purple-50 p-4">
        <Card className="max-w-2xl mx-auto p-8 space-y-8" id={getStepId('upload')}>
          <h2 className="text-2xl font-bold text-center">Upload Your Item</h2>
          <div className="grid gap-6 md:grid-cols-2">
            <Button
              onClick={() => setUploadType('top')}
              className={`p-6 h-auto ${uploadType === 'top' ? 'ring-2 ring-blue-500' : ''}`}
              variant="outline"
              id={getActionId('select_top', 'upload')}
            >
              Upload a Top
            </Button>
            <Button
              onClick={() => setUploadType('bottom')}
              className={`p-6 h-auto ${uploadType === 'bottom' ? 'ring-2 ring-blue-500' : ''}`}
              variant="outline"
              id={getActionId('select_bottom', 'upload')}
            >
              Upload a Bottom
            </Button>
          </div>
          {uploadType && (
            <div className="mt-6">
              <OutfitUpload onComplete={handleUploadComplete} />
            </div>
          )}
        </Card>
      </div>
    );
  }

  if (step === 'demo') {
    return (
        <div className="max-w-4xl mx-auto p-6 space-y-8 relative" id={getStepId('demo')}>
          <BackButton 
            onClick={() => setStep('choice')} 
            id="onboarding_back_from_demo_suggestion"
          />
          <div className="max-w-6xl mx-auto mt-10 px-4">
            <h2 className="text-2xl font-semibold mb-6">Generate Outfits</h2>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
              <form onSubmit={(e) => {
                e.preventDefault();
                if (!hasGeneratedSuggestion) {
                  setIsGenerating(true);
                  setTimeout(() => {
                    setIsGenerating(false);
                    setHasGeneratedSuggestion(true);
                  }, 2000);
                }
              }} className="flex-1 w-full">
                <div className="relative flex flex-col sm:block gap-2">
                  <input
                    type="text"
                    value="Casual meetups with friends"
                    disabled
                    className="prompt-textarea w-full p-4 border border-gray-200 rounded-[24px] shadow-sm whitespace-nowrap focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all sm:pr-[140px]"
                    style={{
                      minHeight: '52px',
                      height: 'auto'
                    }}
                  />
                  <button
                    id="onboarding-generate-button"
                    type="submit"
                    disabled={isGenerating || hasGeneratedSuggestion}
                    className={`generate-button w-full sm:w-auto sm:absolute sm:right-2 sm:top-2 ${
                      hasGeneratedSuggestion 
                        ? 'bg-gray-400 cursor-not-allowed' 
                        : 'bg-gradient-to-r from-blue-500 to-purple-600 hover:from-purple-500 hover:to-blue-600'
                    } text-white px-6 py-2 rounded-full transition-all duration-300 shadow-sm`}
                  >
                    {isGenerating ? (
                      <span className="flex items-center justify-center">
                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Generating
                      </span>
                    ) : hasGeneratedSuggestion ? 'Generated' : 'Generate'}
                  </button>
                </div>
              </form>
            </div>

            {/* Show suggestion content immediately after generation */}
            {hasGeneratedSuggestion && (
              <div className="mt-4 sm:mt-6 bg-white rounded-xl shadow-md overflow-hidden">
                <div className="p-3 sm:p-6">
                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-3 mb-4">
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => setShowNotes(!showNotes)}
                        className="text-xs sm:text-sm text-blue-600 hover:text-blue-700 flex items-center gap-1"
                      >
                        {showNotes ? 'Hide Notes' : 'Show Notes'}
                        <svg
                          className={`w-3 h-3 sm:w-4 sm:h-4 transition-transform duration-200 ${showNotes ? 'rotate-180' : ''}`}
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                      </button>
                    </div>
                  </div>

                  {showNotes && (
                    <div className="suggestion-notes bg-gray-50 p-3 sm:p-4 rounded-lg mb-4 text-sm sm:text-base">
                      <ReactMarkdown>{mockSuggestion.suggestion}</ReactMarkdown>
                    </div>
                  )}

                  <div className="suggested-outfits grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-4 gap-2 sm:gap-4 mb-4 sm:mb-6">
                    {mockSuggestion.suggested_outfits.map((outfit) => (
                      <div key={outfit.id} className="relative group aspect-[3/4]">
                        <img
                          src={outfit.image_url}
                          alt={`Suggested ${outfit.category}`}
                          className="w-full h-full object-cover rounded-lg shadow-sm transition-transform duration-300 group-hover:scale-[1.02]"
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-10 transition-all duration-300 rounded-lg"></div>
                        <div className="absolute top-2 right-2 bg-black bg-opacity-50 text-white px-2 py-1 rounded text-sm">
                          {outfit.category}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="flex flex-col items-center mt-8 space-y-4">
                    <h3 className="text-2xl text-center font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">
                      Want to See How It Works?
                    </h3>
                    <p className="text-gray-600 text-center">
                      Let's analyze an outfit to see how our AI understands fashion.
                    </p>
                    <button
                      onClick={() => setStep('analysis_demo')}
                      className="group relative w-auto inline-flex items-center justify-center mb-12 px-8 py-3 bg-gradient-to-r from-blue-500 to-purple-600 text-white text-lg font-medium rounded-full hover:from-purple-500 hover:to-blue-600 transition-all duration-300 shadow-md hover:shadow-lg transform hover:scale-[1.02]"
                      id={getNavigationId('next', 'demo')}
                    >
                      <span id="onboarding-try-image-analysis-button" className="flex items-center gap-2">
                        Try Image Analysis
                        <svg 
                          className="w-5 h-5 group-hover:translate-x-1 transition-transform duration-200" 
                          fill="none" 
                          stroke="currentColor" 
                          viewBox="0 0 24 24"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
    );
  }

  if (step === 'analysis_demo') {
    return (
        <div className="max-w-4xl mx-auto p-6 space-y-8 relative" id={getStepId('analysis_demo')}>
          {!isCustomUpload && (
            <BackButton 
              onClick={() => {
                setStep('demo');
                setDemoImage(null);
                setAnalysisResult(null);
                setIsAnalyzing(false);
                setHasAnalyzedImage(false);
              }}
              id="onboarding_back_from_upload_selection"
            />
          )}
          <div className="max-w-6xl mx-auto mt-10 px-4">
            <h2 className="text-2xl font-semibold mb-6">Outfit Analysis Demo</h2>
            
            {!demoImage && !isAnalyzing && (
              <div className="space-y-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Upload Option */}
                  <div 
                    className="border-2 border-dashed border-blue-500 rounded-lg p-8 text-center cursor-pointer hover:bg-blue-50 transition-colors"
                    onClick={() => fileInputRef.current?.click()}
                    id={getActionId('upload_own_image', 'analysis_demo')}
                  >
                    <svg className="w-12 h-12 mx-auto text-blue-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    <h3 className="text-lg font-semibold mb-2">Upload Your Own Image</h3>
                    <p className="text-gray-600">Choose a photo of clothing to analyze</p>
                    <input
                      ref={fileInputRef}
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                          setDemoImage(file);
                          handleAnalyzeImage(file);
                        }
                      }}
                    />
                  </div>

                  {/* Demo Images */}
                  <div className="border-2 border-dashed border-purple-500 rounded-lg p-8 text-center">
                    <svg className="w-12 h-12 mx-auto text-purple-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                    </svg>
                    <h3 className="text-lg font-semibold mb-2">Try Demo Images</h3>
                    <p className="text-gray-600 mb-4">Select from our example images</p>
                    <div className="grid grid-cols-2 gap-4">
                      <img
                        src={demoTop}
                        alt="Demo Dress"
                        className="w-full h-32 object-cover rounded-lg cursor-pointer hover:opacity-75 transition-opacity"
                        onClick={() => handleAnalyzeDemoImage('demo-top')}
                        id={getActionId('demo_top_selected', 'analysis_demo')}
                      />
                      <img
                        src={demoShoes}
                        alt="Demo Shoes"
                        className="w-full h-32 object-cover rounded-lg cursor-pointer hover:opacity-75 transition-opacity"
                        onClick={() => handleAnalyzeDemoImage('demo-shoes')}
                        id={getActionId('demo_shoes_selected', 'analysis_demo')}
                      />
                    </div>
                  </div>
                </div>

                <p className="text-center text-gray-500 text-sm">
                  Choose either option to see how our AI analyzes fashion items
                </p>
              </div>
            )}

            {isAnalyzing && (
              <div className="text-center py-12">
                <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-500 mx-auto mb-4"></div>
                <p className="text-lg text-gray-600">Analyzing your fashion item...</p>
                <p className="text-sm text-gray-500 mt-2">This might take a few seconds</p>
              </div>
            )}

            {analysisResult && !isAnalyzing && (
              <div className="bg-white rounded-xl shadow-lg p-6 space-y-6">
                <div className="flex flex-col md:flex-row md:items-start gap-6">
                  <div className="w-full md:w-1/3">
                    <img
                      src={URL.createObjectURL(demoImage)}
                      alt="Analyzed outfit"
                      className="w-full rounded-lg"
                    />
                  </div>
                  <div className="w-full md:w-2/3 space-y-4">
                    <div>
                      <h3 className="text-lg font-semibold mb-2">Analysis Results</h3>
                      <p className="text-gray-600">{analysisResult.detailed_description}</p>
                    </div>
                    
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      <div>
                        <h4 className="font-medium text-gray-700">Category</h4>
                        <p className="text-gray-600 capitalize">{analysisResult.category}</p>
                      </div>
                      <div>
                        <h4 className="font-medium text-gray-700">Color</h4>
                        <p className="text-gray-600 capitalize">{analysisResult.color}</p>
                      </div>
                      <div>
                        <h4 className="font-medium text-gray-700">Seasons</h4>
                        <p className="text-gray-600 capitalize">{analysisResult.seasons.join(', ')}</p>
                      </div>
                      <div>
                        <h4 className="font-medium text-gray-700">Activities</h4>
                        <p className="text-gray-600 capitalize">{analysisResult.activities.join(', ')}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-center mt-8">
                  <button
                    onClick={() => setStep('complete')}
                    className="group relative w-auto inline-flex items-center justify-center px-8 py-3 bg-gradient-to-r from-blue-500 to-purple-600 text-white text-lg font-medium rounded-full hover:from-purple-500 hover:to-blue-600 transition-all duration-300 shadow-md hover:shadow-lg transform hover:scale-[1.02]"
                    id="onboarding_discover_style_button"
                  >
                    <span id="onboarding_discover_style_button" className="flex items-center gap-2">
                      Discover Your Style
                      <svg
                        id="onboarding_discover_style_button"
                        className="w-5 h-5 group-hover:translate-x-1 transition-transform duration-200" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }

  if (step === 'complete') {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-50 to-purple-50 flex items-center justify-center p-4 relative" id={getStepId('complete')}>
        <BackButton 
          onClick={() => setStep('analysis_demo')} 
          id="onboarding_back_from_complete"
        />
        <Card className="max-w-2xl w-full p-8 space-y-8">
          <div className="space-y-6 text-center">
            <h2 className="text-3xl font-bold">Ready to Transform Your Style?</h2>
            <p className="text-lg text-gray-600 max-w-xl mx-auto">
              Join thousands of fashion enthusiasts who are already using StyleGen AI to create stunning outfits effortlessly
            </p>

            <div className="flex flex-col sm:flex-row justify-center items-center gap-4 py-6">
              <div className="text-center px-6">
                <div className="text-3xl font-bold text-blue-600">400+</div>
                <div className="text-sm text-gray-600">Active Users</div>
              </div>
              <div className="hidden sm:block w-px h-12 bg-gray-200"></div>
              <div className="text-center px-6">
                <div className="text-3xl font-bold text-purple-600">16,000+</div>
                <div className="text-sm text-gray-600">Outfits Generated</div>
              </div>
              <div className="hidden sm:block w-px h-12 bg-gray-200"></div>
              <div className="text-center px-6">
                <div className="text-3xl font-bold text-blue-600">11,000+</div>
                <div className="text-sm text-gray-600">Outfits Analyzed</div>
              </div>
            </div>

            <Button
              onClick={() => {
                handleComplete();
                navigate('/pricing');
              }}
              className="w-full max-w-md mx-auto rounded-full text-lg py-6 bg-gradient-to-r from-blue-500 to-purple-600 hover:from-purple-500 hover:to-blue-600 transform hover:scale-[1.02] transition-all duration-300 shadow-lg hover:shadow-xl"
              id={getActionId('complete_onboarding', 'complete')}
            >
              <span id="onboarding_get_started_button" className="flex items-center justify-center gap-2">
                Get Started Now
                <svg 
                  id="onboarding_get_started_button"
                  className="w-5 h-5" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                </svg>
              </span>
            </Button>
          </div>
        </Card>
      </div>
    );
  }

  return null;
};

export default WelcomeOnboarding; 