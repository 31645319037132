import React, { useEffect, useState } from 'react';
import { client } from '../axiosClient';
import { useNavigate, useLocation } from 'react-router-dom';
import { API_URL } from '../config';
import { FaFacebook, FaGoogle } from 'react-icons/fa';
import logo from '../assets/logo_circular.png';

const LoginRegister = ({ setCurrentUser }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const accessToken = params.get('access_token');
    const refreshToken = params.get('refresh_token');
    const error = params.get('error');

    if (error) {
      console.error("Authentication error:", error);
      setErrorMessage(`Authentication failed: ${error}`);
    } else if (accessToken && refreshToken) {
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('refresh_token', refreshToken);
      client.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      fetchUserData();
    }
  }, [location, navigate]);

  const fetchUserData = async () => {
    try {
      const userResponse = await client.get("/api/user/");
      const userData = userResponse.data.user;
      setCurrentUser(userData);
      
      // Set user email in data layer for GTM
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'user_email': userData.email
      });
      
      navigate('/');  // Redirect to home page after successful login
    } catch (error) {
      console.error("Error fetching user data:", error);
      setErrorMessage("Failed to fetch user data. Please try logging in again.");
    }
  };

  const handleGoogleLogin = async () => {
    try {
      window.location.href = `${API_URL}/api/google/`;
    } catch (error) {
      console.error("Error logging in with Google:", error);
      setErrorMessage("Failed to log in with Google. Please try again later.");
    }
  };

  const handleFacebookLogin = async () => {
    try {
      window.location.href = `${API_URL}/api/facebook/login/`;
    } catch (error) {
      console.error("Error logging in with Facebook:", error);
      setErrorMessage("Failed to log in with Facebook. Please try again later.");
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div className="relative group">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-400 to-purple-600 shadow-2xl transform -rotate-3 rounded-3xl group-hover:rotate-0 transition-all duration-500"></div>
          <div className="relative bg-white/95 backdrop-blur-sm shadow-xl rounded-3xl px-8 py-12 sm:px-12 sm:py-16">
            <div className="flex flex-col items-center space-y-4 mb-8">
              <img src={logo} alt="logo" className="w-16 h-16 mb-3 transform transition-transform duration-300 hover:scale-110" />
              <h2 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">Stylegen AI</h2>
            </div>
            <div className="text-center mb-4">
              <h2 className="text-lg font-medium text-gray-600">Continue with</h2>
            </div>
            {errorMessage && <div className="text-red-500 text-sm text-center mb-6 px-4 py-2 bg-red-50 rounded-lg">{errorMessage}</div>}
            <div className="space-y-6">
              <button
                onClick={handleGoogleLogin}
                className="w-full flex items-center justify-center py-3 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-all duration-300 transform hover:scale-[1.02] active:scale-95"
                id="google_login"
              >
                <FaGoogle className="w-5 h-5 mr-3" />
                Google
              </button>
              <button
                onClick={handleFacebookLogin}
                className="w-full flex items-center justify-center py-3 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-white bg-blue-600 hover:from-blue-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-300 transform hover:scale-[1.02] active:scale-95"
                id="facebook_login"
              >
                <FaFacebook className="w-5 h-5 mr-3" />
                Facebook
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginRegister;
