import React, { useState, useCallback } from 'react';
import { client } from '../axiosClient';
import { useToast } from './ui/use-toast';

const Feedback = () => {
  const [title, setTitle] = useState('');
  const [feedback, setFeedback] = useState('');
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();

  const handleImageChange = useCallback((e) => {
    const files = Array.from(e.target.files);
    
    // Validate number of images
    if (files.length > 5 || selectedImages.length + files.length > 5) {
      toast({
        title: "Too many images",
        description: "You can only upload up to 5 images",
        variant: "destructive"
      });
      return;
    }

    // Validate file types and sizes
    const invalidFiles = files.filter(file => !file.type.startsWith('image/'));
    const largeFiles = files.filter(file => file.size > 5 * 1024 * 1024); // 5MB

    if (invalidFiles.length > 0) {
      toast({
        title: "Invalid file type",
        description: "Please upload only image files",
        variant: "destructive"
      });
      return;
    }

    if (largeFiles.length > 0) {
      toast({
        title: "File too large",
        description: "Each image must be less than 5MB",
        variant: "destructive"
      });
      return;
    }

    // Create preview URLs and store files
    const newImagePreviews = files.map(file => URL.createObjectURL(file));
    setSelectedImages(prev => [...prev, ...newImagePreviews]);
    setImageFiles(prev => [...prev, ...files]);
  }, [selectedImages.length, toast]);

  const removeImage = useCallback((index) => {
    setSelectedImages(prev => prev.filter((_, i) => i !== index));
    setImageFiles(prev => prev.filter((_, i) => i !== index));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!title.trim() || !feedback.trim()) {
      toast({
        title: "Missing information",
        description: "Please provide both title and feedback",
        variant: "destructive"
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('feedback', feedback);
      
      // Append images with sequential keys
      imageFiles.forEach((file, index) => {
        formData.append(`image_${index}`, file);
      });

      await client.post('/api/send-feedback/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast({
        title: "Success",
        description: "Thank you for your feedback!",
      });

      // Reset form
      setTitle('');
      setFeedback('');
      setSelectedImages([]);
      setImageFiles([]);
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast({
        title: "Error",
        description: error.response?.data?.error || "Failed to submit feedback",
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-8">
      <div className="max-w-3xl mx-auto mt-10 px-4">
        <h2 className="text-2xl font-semibold mb-6">Send Feedback</h2>
        
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Title Input */}
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
              Title
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Title"
              className="w-full p-3 border border-gray-200 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all"
              maxLength={100}
            />
          </div>

          {/* Feedback Textarea */}
          <div>
            <label htmlFor="feedback" className="block text-sm font-medium text-gray-700 mb-1">
              Feedback
            </label>
            <textarea
              id="feedback"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Please share your thoughts, suggestions, feature requests or report any issues..."
              rows={5}
              className="w-full p-3 border border-gray-200 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all resize-none"
            />
          </div>

          {/* Image Upload */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Attach Images (Optional)
            </label>
            <div className="mt-2 space-y-4">
              {/* Image Preview Grid */}
              {selectedImages.length > 0 && (
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mb-4">
                  {selectedImages.map((preview, index) => (
                    <div key={index} className="relative group aspect-square">
                      <img
                        src={preview}
                        alt={`Preview ${index + 1}`}
                        className="w-full h-full object-cover rounded-lg"
                      />
                      <button
                        type="button"
                        onClick={() => removeImage(index)}
                        className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                        id={`remove_feedback_image`}
                      >
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              )}

              {/* Upload Button */}
              {selectedImages.length < 5 && (
                <div className="flex items-center justify-center w-full">
                  <label className="w-full flex flex-col items-center justify-center px-4 py-6 bg-white text-blue-500 rounded-lg shadow-lg tracking-wide border border-blue-500 cursor-pointer hover:bg-blue-500 hover:text-white transition-all duration-300">
                    <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    <span className="mt-2 text-sm">Select Images</span>
                    <input
                      type="file"
                      className="hidden"
                      accept="image/*"
                      multiple
                      onChange={handleImageChange}
                      id="select_feedback_images"
                    />
                  </label>
                </div>
              )}
              <p className="text-sm text-gray-500 mt-2">
                You can upload up to 5 images (max 5MB each)
              </p>
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={isSubmitting || (!title.trim() || !feedback.trim())}
            className="w-full sm:w-auto bg-gradient-to-r from-blue-500 to-purple-600 text-white px-6 py-3 rounded-full hover:from-purple-500 hover:to-blue-600 disabled:from-gray-400 disabled:to-gray-500 transition-all duration-300 shadow-sm flex items-center justify-center"
            id="submit_feedback"
          >
            {isSubmitting ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Sending...
              </>
            ) : (
              'Send Feedback'
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Feedback;
