import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import { client } from '../axiosClient';
import { Button } from './ui/button';
import { Input } from './ui/input';
import AvatarEditor from 'react-avatar-editor';
import { useToast } from './ui/use-toast';
import Joyride from 'react-joyride';
import { useStartTour } from '../hooks/startTour';

// Memoized Modal Components
const AvatarUploadModal = memo(({ onClose, onSave, currentAvatar }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [scale, setScale] = useState(1);
  const [error, setError] = useState('');
  const editorRef = useRef(null);

  const handleFileChange = useCallback((e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(URL.createObjectURL(e.target.files[0]));
      setError('');
    }
  }, []);

  const handleSave = useCallback(() => {
    if (editorRef.current) {
      try {
        const canvas = editorRef.current.getImageScaledToCanvas();
        canvas.toBlob((blob) => {
          const formData = new FormData();
          formData.append('avatar', blob, 'avatar.png');
          onSave(formData);
        });
      } catch (err) {
        setError('Failed to process image. Please try another image.');
        console.error('Error processing image:', err);
      }
    }
  }, [onSave]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Edit Profile Picture</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <svg id="edit-avatar-modal-close-button" className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="space-y-4">
          <Input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="w-full"
          />

          {error && (
            <div className="text-red-500 text-sm">{error}</div>
          )}

          {selectedFile && (
            <div className="space-y-4">
              <div className="flex justify-center">
                <AvatarEditor
                  ref={editorRef}
                  image={selectedFile}
                  width={200}
                  height={200}
                  border={50}
                  borderRadius={100}
                  color={[255, 255, 255, 0.6]}
                  scale={scale}
                  rotate={0}
                />
              </div>
              
              <div className="space-y-2">
                <label className="block text-sm font-medium">Zoom</label>
                <input
                  type="range"
                  min="1"
                  max="2"
                  step="0.01"
                  value={scale}
                  onChange={(e) => setScale(parseFloat(e.target.value))}
                  className="w-full"
                />
              </div>

              <div className="flex justify-end space-x-2">
                <Button onClick={onClose} variant="outline">Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

AvatarUploadModal.displayName = 'AvatarUploadModal';

const Profile = memo(({ user, onUserUpdate }) => {
  const { toast } = useToast();
  const { runTour, steps, startTour, config, pushToDataLayer } = useStartTour('profile');
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingAvatar, setIsEditingAvatar] = useState(false);
  const [profileData, setProfileData] = useState({
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    avatar: user.avatar || null,
  });

  // Memoized handlers
  const handleProfileUpdate = useCallback(async (e) => {
    e.preventDefault();
    try {
      const response = await client.patch('/api/profile/update/', profileData);
      toast({
        title: "Success",
        description: "Profile updated successfully",
      });
      onUserUpdate(response.data);
      setIsEditing(false);
    } catch (err) {
      toast({
        title: "Error",
        description: err.response?.data?.error || 'Failed to update profile',
        variant: "destructive",
      });
    }
  }, [profileData, onUserUpdate, toast]);

  const handleAvatarUpdate = useCallback(async (formData) => {
    try {
      const response = await client.patch('/api/profile/update-avatar/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const newAvatar = response.data.avatar;
      setProfileData(prev => ({ ...prev, avatar: newAvatar }));
      onUserUpdate({ ...user, avatar: newAvatar });
      setIsEditingAvatar(false);
      toast({
        title: "Success",
        description: "Profile picture updated successfully",
      });
    } catch (err) {
      toast({
        title: "Error",
        description: err.response?.data?.error || 'Failed to update profile picture',
        variant: "destructive",
      });
    }
  }, [user, onUserUpdate, toast]);

  const handleJoyrideCallback = useCallback((data) => {
    const { action, index, status, step, type } = data;

    // Track tour interactions
    if (type === 'step:after' || type === 'tour:end' || type === 'tour:start') {
      pushToDataLayer(
        action,
        status,
        index + 1,
        step?.target || 'body'
      );
    }

    if (status === 'finished' || status === 'skipped') {
      localStorage.setItem('hasSeenProfileTour', 'true');
    }
  }, [pushToDataLayer]);

  useEffect(() => {
    const hasSeenTour = localStorage.getItem('hasSeenProfileTour');
    if (!hasSeenTour) {
      startTour();
      localStorage.setItem('hasSeenProfileTour', 'false');
    }
  }, [startTour]);

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-8">
      <Joyride
        {...config}
        run={runTour}
        steps={steps}
        callback={handleJoyrideCallback}
      />
      
      {/* Profile Header */}
      <div className="flex items-center space-x-6">
        <div className="relative group profile-avatar">
          <div className="w-24 h-24 rounded-full overflow-hidden group-hover:opacity-75 transition-opacity duration-200">
            {profileData.avatar ? (
              <img
                src={profileData.avatar}
                alt="Profile"
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="w-full h-full bg-gradient-to-r from-purple-400 to-pink-500 flex items-center justify-center text-white text-2xl font-bold">
                {user.first_name ? user.first_name[0] : user.email[0]}
              </div>
            )}
          </div>
          <button
            id="edit-avatar-button"
            onClick={() => setIsEditingAvatar(true)}
            className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-50 transition-all duration-200 rounded-full"
          >
            <svg
              className="w-8 h-8 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-200"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
          </button>
        </div>
        <div>
          <h1 className="text-2xl font-bold">
            {user.first_name ? `${user.first_name} ${user.last_name}` : 'User Profile'}
          </h1>
          <p className="text-gray-600">{user.email}</p>
          <p className="text-sm mt-1 text-purple-600 font-medium" id="profile-subscription-plan-name">
            {(user.subscriptionPlan?.toUpperCase()?.split('_').join(' ')) || 'Free Plan'}
          </p>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex flex-wrap gap-4">
        <a 
          href={user?.hasSubscription 
            ? "https://stylegenai.lemonsqueezy.com/billing" 
            : "/pricing"}
          target="_blank"
          rel="noopener noreferrer"
          className="subscription-button inline-flex items-center justify-center px-6 py-2 border-0 rounded-full text-sm font-medium text-white bg-gradient-to-l from-purple-500 to-pink-500 hover:from-pink-500 hover:to-purple-500 transition-all duration-200 shadow-lg"
          id="profile-manage-subscription-button"
        >
          {user?.hasSubscription ? 'Manage Subscription' : 'Upgrade Plan'}
        </a>
        <Button 
          onClick={() => setIsEditing(!isEditing)} 
          className={`edit-profile-button ${isEditing ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-700'} text-white transition-colors duration-200 rounded-full`}
          id="profile-edit-button"
        >
          {isEditing ? 'Cancel Edit' : 'Edit Profile'}
        </Button>
      </div>

      {/* Edit Profile Form */}
      {isEditing && (
        <form onSubmit={handleProfileUpdate} className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">First Name</label>
            <Input
              type="text"
              value={profileData.first_name}
              onChange={(e) => setProfileData({...profileData, first_name: e.target.value})}
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Last Name</label>
            <Input
              type="text"
              value={profileData.last_name}
              onChange={(e) => setProfileData({...profileData, last_name: e.target.value})}
            />
          </div>
          <Button 
            type="submit"
            id="save_profile_changes"
          >
            Save Changes
          </Button>
        </form>
      )}

      {/* Promo Code Section */}
      <div className="mt-8 space-y-4">
        <h2 className="text-xl font-semibold">Have a Promo Code?</h2>
        <form onSubmit={async (e) => {
          e.preventDefault();
          const code = e.target.promoCode.value;
          
          try {
            const response = await client.post('/api/promo-code/', { code });
            toast({
              title: "Success",
              description: response.data.message,
            });
            onUserUpdate({ ...user, subscriptionPlan: response.data.plan });
            e.target.reset();
          } catch (err) {
            toast({
              title: "Error",
              description: err.response?.data?.error || 'Failed to apply promo code',
              variant: "destructive",
            });
          }
        }} className="flex gap-2">
          <Input
            type="text"
            name="promoCode"
            placeholder="Enter your promo code"
            className="max-w-xs"
          />
          <Button 
            className="bg-blue-600 hover:bg-blue-700" 
            type="submit"
            id="apply_promo_code"
          >
            Apply Code
          </Button>
        </form>
      </div>

      {/* Avatar Upload Modal */}
      {isEditingAvatar && (
        <AvatarUploadModal
          onClose={() => setIsEditingAvatar(false)}
          onSave={handleAvatarUpdate}
          currentAvatar={profileData.avatar}
        />
      )}
    </div>
  );
});

Profile.displayName = 'Profile';

export default Profile;