import React, { useState, useRef, useCallback, useEffect } from 'react';
import { client } from '../axiosClient';
import { Button } from './ui/button';
import Joyride from 'react-joyride';
import { useStartTour } from '../hooks/startTour';
import { useToast } from './ui/use-toast';
import { ToastAction } from './ui/toast';
import { Dialog, DialogContent, DialogTitle, DialogDescription, DialogHeader } from './ui/dialog';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import Step1 from '../assets/exampleUpload1.webp';
import Step2 from '../assets/exampleUpload2.webp';
import Step3 from '../assets/exampleUpload3.webp';
import Step4 from '../assets/exampleUpload4.webp';
import Step5 from '../assets/exampleUpload5.webp';
import { Checkbox } from './ui/checkbox';

const OutfitUpload = ({ onUploadSuccess }) => {
  const {toast} = useToast();
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
  const dragCounter = useRef(0);
  const [loadingText, setLoadingText] = useState('');
  const loadingTexts = ['Removing Background', 'Analyzing Images', 'Processing', 'This might take a while...'];
  const loadingTextIndex = useRef(0);
  const { runTour, steps, startTour, config, pushToDataLayer } = useStartTour('outfitUpload');
  const [hasSubscription, setHasSubscription] = useState(true); // Default to true to prevent flicker
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [rotations, setRotations] = useState({}); // Track rotation degrees for each image
  const [showRotationConfirm, setShowRotationConfirm] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [pendingSubmit, setPendingSubmit] = useState(false);

  // Update helpImages to use SVG components
  const helpImages = [
    { Component: Step1, alt: 'Upload Step 1' },
    { Component: Step2, alt: 'Upload Step 2' },
    { Component: Step3, alt: 'Upload Step 3' },
    { Component: Step4, alt: 'Upload Step 4' },
    { Component: Step5, alt: 'Upload Step 5' },
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % helpImages.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + helpImages.length) % helpImages.length);
  };

  useEffect(() => {
    // Check subscription status when component mounts
    const checkSubscription = async () => {
      try {
        const response = await client.get('/api/user/statistics/');
        setHasSubscription(response.data.has_subscription);
      } catch (error) {
        console.error('Error checking subscription:', error);
        setHasSubscription(false);
      }
    };

    checkSubscription();
  }, []);

  useEffect(() => {
    const hasSeenTour = localStorage.getItem('hasSeenOutfitUploadTour');
    if (!hasSeenTour) {
      startTour();
      localStorage.setItem('hasSeenOutfitUploadTour', 'false');
    }
  }, [startTour]);

  const handleJoyrideCallback = (data) => {
    const { action, index, status, step, type } = data;

    // Track tour interactions
    if (type === 'step:after' || type === 'tour:end' || type === 'tour:start') {
      pushToDataLayer(
        action,
        status,
        index + 1,
        step?.target || 'body'
      );
    }

    if (status === 'finished' || status === 'skipped') {
      localStorage.setItem('hasSeenOutfitUploadTour', 'true');
    }
  };

  useEffect(() => {
    let interval;
    if (uploading) {
      interval = setInterval(() => {
        setLoadingText(loadingTexts[loadingTextIndex.current]);
        loadingTextIndex.current = (loadingTextIndex.current + 1) % loadingTexts.length;
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [uploading]);

  const handleImageChange = (e) => {
    try {
      if (!hasSubscription) {
        toast({
          title: "Subscription Required",
          description: "Please upgrade to a premium plan to upload outfits",
          variant: "destructive"
        });
        return;
      }

      const files = e.target.files || e.dataTransfer.files;
      const newImages = Array.from(files);
      
      if (images.length + newImages.length > 10) {
        setErrors(prevErrors => ({
          ...prevErrors,
          imageLimit: "Upload up to 10 images at once"
        }));
        toast({
          title: "Too many images",
          description: "You can only upload up to 10 images",
          variant: "destructive"
        });
        return;
      }
      
      setImages(prevImages => [...prevImages, ...newImages]);
      setErrors(prevErrors => ({
        ...prevErrors,
        imageLimit: null
      }));
    } catch (error) {
      setPendingSubmit(false);
      let errorMessage = 'An error occurred while uploading. Please try again.';
      let errorTitle = 'Upload Error';

      // Handle CORS errors that might occur with large payloads
      if (error.message === 'Network Error') {
        errorMessage = 'Upload size too large. Please reduce the size of your images or upload fewer images.';
        errorTitle = 'File Size Error';
        setErrors({ general: errorMessage });
      }
      else if (error.response) {
        if (error.response.status === 403) {
          errorMessage = error.response.data?.detail || 'Subscription error';
          errorTitle = 'Subscription Error';
          setErrors({ subscription: errorMessage });
        } else if (error.response.status === 413) {
          errorMessage = error.response.data?.detail || 'Upload size too large. Please reduce the size of your images or upload fewer images.';
          errorTitle = 'File Size Error';
          setErrors({ general: errorMessage });
        } else {
          errorMessage = error.response.data?.detail || 'An error occurred while uploading.';
          setErrors({ general: errorMessage });
        }
      } else if (error.request) {
        if (error.code === 'ECONNABORTED') {
          errorMessage = 'Upload timed out. Please try with smaller files or check your connection.';
          errorTitle = 'Timeout Error';
        } else {
          errorMessage = 'Network error. Please check your connection and try again.';
          errorTitle = 'Network Error';
        }
        setErrors({ general: errorMessage });
      }

      toast({
        title: errorTitle,
        description: errorMessage,
        variant: 'destructive',
        duration: 5000,
      });
    } finally {
      setUploading(false);
      setLoadingText('');
      loadingTextIndex.current = 0;
    }
  };

  const handleRemoveImage = (index) => {
    setImages(prevImages => prevImages.filter((_, i) => i !== index));
  };

  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragIn = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  }, []);

  const handleDragOut = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setIsDragging(false);
    }
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    dragCounter.current = 0;
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleImageChange(e);
      e.dataTransfer.clearData();
    }
  }, [handleImageChange]);

  // Helper function to rotate image
  const rotateImage = (index) => {
    setRotations(prev => ({
      ...prev,
      [index]: ((prev[index] || 0) + 90) % 360
    }));
  };

  // Modify handleSubmit to show confirmation if needed
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (images.length === 0 || !hasSubscription) return;

    // If user hasn't disabled the confirmation
    const shouldShowConfirm = !localStorage.getItem('hideRotationConfirm');

    if (shouldShowConfirm) {
      setPendingSubmit(true);
      setShowRotationConfirm(true);
      return;
    }

    // Proceed with upload
    await performUpload();
  };

  const performUpload = async () => {
    setUploading(true);
    setErrors({});
    
    console.log('Starting image processing...');
    
    // Compress all images, rotated or not
    const processedImages = await Promise.all(images.map(async (image, index) => {
      const rotation = rotations[index] || 0;
      console.log(`Processing image ${index + 1} (${image.name}) - Rotation: ${rotation}°`);
      const processedImage = await rotateImageFile(image, rotation);
      console.log(`Image ${index + 1} processed - Original size: ${(image.size / 1024 / 1024).toFixed(2)}MB, Compressed size: ${(processedImage.size / 1024 / 1024).toFixed(2)}MB`);
      return processedImage;
    }));

    console.log('All images processed, preparing form data...');
    
    const formData = new FormData();
    processedImages.forEach((image, index) => {
      formData.append(`image_${index}`, image);
    });

    try {
      const response = await client.post('/api/upload-outfits/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 300000, // 5 minutes
      });
      
      if (response.data) {
        console.log('Success:', response.data);
        setImages([]);
        setPendingSubmit(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }

        toast({
          title: 'Upload Successful',
          description: 'Uploads are queued for analysis. It takes about few minutes.',
          duration: 7000,
          action: (
            <ToastAction altText="View in closet" onClick={() => window.location.href = '/my-closet'}>
              See in closet
            </ToastAction>
          ),
        });

        if (onUploadSuccess) {
          onUploadSuccess();
        }
        return;
      }
    } catch (error) {
      console.error('Error uploading images:', error);
      setPendingSubmit(false);
      let errorMessage = 'An error occurred while uploading. Please try again.';
      let errorTitle = 'Upload Error';

      // Handle CORS errors that might occur with large payloads
      if (error.message === 'Network Error') {
        errorMessage = 'Upload size too large. Please reduce the size of your images or upload fewer images.';
        errorTitle = 'File Size Error';
        setErrors({ general: errorMessage });
      }
      else if (error.response) {
        if (error.response.status === 403) {
          errorMessage = error.response.data?.detail || 'Subscription error';
          errorTitle = 'Subscription Error';
          setErrors({ subscription: errorMessage });
        } else if (error.response.status === 413) {
          errorMessage = error.response.data?.detail || 'Upload size too large. Please reduce the size of your images or upload fewer images.';
          errorTitle = 'File Size Error';
          setErrors({ general: errorMessage });
        } else {
          errorMessage = error.response.data?.detail || 'An error occurred while uploading.';
          setErrors({ general: errorMessage });
        }
      } else if (error.request) {
        if (error.code === 'ECONNABORTED') {
          errorMessage = 'Upload timed out. Please try with smaller files or check your connection.';
          errorTitle = 'Timeout Error';
        } else {
          errorMessage = 'Network error. Please check your connection and try again.';
          errorTitle = 'Network Error';
        }
        setErrors({ general: errorMessage });
      }

      toast({
        title: errorTitle,
        description: errorMessage,
        variant: 'destructive',
        duration: 5000,
      });
    } finally {
      setUploading(false);
      setLoadingText('');
      loadingTextIndex.current = 0;
    }
  };

  // Update rotateImageFile to handle compression even when not rotated
  const rotateImageFile = (file, degrees = 0) => {
    return new Promise((resolve) => {
      const img = new Image();
      const reader = new FileReader();
      
      reader.onload = (e) => {
        img.src = e.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          
          // Handle rotation if needed
          if (degrees === 90 || degrees === 270) {
            canvas.width = img.height;
            canvas.height = img.width;
          } else {
            canvas.width = img.width;
            canvas.height = img.height;
          }
          
          ctx.translate(canvas.width / 2, canvas.height / 2);
          ctx.rotate(degrees * Math.PI / 180);
          ctx.drawImage(img, -img.width / 2, -img.height / 2);
          
            console.log(`Compressing image: ${file.name} (${(file.size / 1024 / 1024).toFixed(2)}MB)`);
            
          // Add compression with quality setting
            canvas.toBlob((blob) => {
              const compressedFile = new File([blob], file.name, { 
              type: 'image/jpeg', // Force JPEG format for better compression
                lastModified: Date.now()
              });
              
              console.log(`First compression result: ${(compressedFile.size / 1024 / 1024).toFixed(2)}MB`);
              
            // If the file is still too large, reduce quality further
            if (compressedFile.size > 2 * 1024 * 1024) { // 2MB
                console.log('File still too large, applying additional compression...');
                canvas.toBlob((smallerBlob) => {
                  const finalFile = new File([smallerBlob], file.name, {
                    type: 'image/jpeg',
                    lastModified: Date.now()
                  });
                  console.log(`Final compressed size: ${(finalFile.size / 1024 / 1024).toFixed(2)}MB`);
                  resolve(finalFile);
              }, 'image/jpeg', 0.6); // 60% quality
              } else {
                console.log('Compression successful, file size within limits');
                resolve(compressedFile);
              }
          }, 'image/jpeg', 0.8); // Start with 80% quality
        };
      };
      
      reader.readAsDataURL(file);
    });
  };

  const handleConfirmUpload = () => {
    if (dontShowAgain) {
      localStorage.setItem('hideRotationConfirm', 'true');
    }
    setShowRotationConfirm(false);
    performUpload();
  };

  const Loader = () => {
    return (
      <div className="w-full gap-x-2 flex justify-center items-center flex-col">
        <div className="flex gap-x-3">
          <div className="w-5 bg-[#d991c2] animate-pulse h-5 rounded-full animate-bounce" />
          <div className="w-5 animate-pulse h-5 bg-[#9869b8] rounded-full animate-bounce" />
          <div className="w-5 h-5 animate-pulse bg-[#6756cc] rounded-full animate-bounce" />
        </div>
        <div className="loading-text mt-2">{loadingText}</div>
      </div>
    );
  };

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-8">
      <Joyride
        {...config}
        run={runTour}
        steps={steps}
        callback={handleJoyrideCallback}
      />

      <div className="max-w-3xl mx-auto mt-10 px-4">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Upload Outfits</h2>
          <button
            onClick={() => setShowHelpModal(true)}
            className="text-gray-500 hover:text-gray-700 transition-colors duration-200 flex items-center gap-2"
            aria-label="Show upload help"
            id="see_example_uploads"
          >
            <span className="show-examples text-sm pointer-events-none">See Examples</span>
            <QuestionMarkCircleIcon className="w-5 h-5 pointer-events-none" />
          </button>
        </div>
        
        <div className="space-y-6">
          {!uploading ? (
            <>
              {/* Upload Area */}
              <div className="relative">
                <div
                  id="upload-area"
                  className={`upload-area flex flex-col items-center justify-center w-full px-4 py-6 bg-white rounded-lg shadow-lg tracking-wide border-2 ${
                    !hasSubscription ? 'border-red-500 bg-red-50' :
                    isDragging ? 'border-blue-500 bg-blue-50' : 'border-blue-500 border-dashed'
                  } ${hasSubscription ? 'cursor-pointer hover:bg-blue-50' : 'cursor-not-allowed'} transition-all duration-300`}
                  onDragEnter={hasSubscription ? handleDragIn : undefined}
                  onDragLeave={hasSubscription ? handleDragOut : undefined}
                  onDragOver={hasSubscription ? handleDrag : undefined}
                  onDrop={hasSubscription ? handleDrop : undefined}
                  onClick={hasSubscription ? () => fileInputRef.current?.click() : undefined}
                >
                  {hasSubscription ? (
                    <>
                      <svg className="w-10 h-10 text-blue-500 pointer-events-none" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path className="pointer-events-none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                      </svg>
                      <p className="mt-2 text-sm text-gray-600 pointer-events-none">
                        {isDragging ? 'Drop files here' : 'Drag & Drop files here or click to browse'}
                      </p>
                      <p className="text-xs text-gray-500 mt-1 pointer-events-none">Upload up to 10 images at once</p>
                    </>
                  ) : (
                    <div className="text-center">
                      <p className="mt-2 text-md text-red-600 font-medium">Premium Feature</p>
                      <p className="text-xs text-red-500 mt-1">Please upgrade to upload outfits</p>
                      <Button 
                        onClick={() => window.location.href = '/pricing'} 
                        className="mt-4 bg-gradient-to-r from-blue-500 to-purple-600 text-white px-4 py-2 rounded-full hover:from-purple-500 hover:to-blue-600 transition-all duration-300"
                      >
                        Upgrade Plan
                      </Button>
                    </div>
                  )}
                  <input
                    ref={fileInputRef}
                    type="file"
                    multiple
                    onChange={handleImageChange}
                    className="hidden"
                    accept="image/*"
                    disabled={!hasSubscription}
                    id="drag_and_drop_input"
                  />
                </div>
              </div>

              {/* Help Modal */}
              <Dialog open={showHelpModal} onOpenChange={setShowHelpModal}>
                <DialogHeader>
                  <DialogTitle></DialogTitle>
                  <DialogDescription>
                  </DialogDescription>
                </DialogHeader>
                <DialogContent className="sm:max-w-[800px] h-[85vh] sm:h-auto max-h-[90vh] p-0 overflow-hidden">
                  <div className="relative h-full flex flex-col">
                    {/* Update image container styling */}
                    <div className="flex-1 flex items-center justify-center p-2 sm:p-4 overflow-hidden">
                      <img 
                        src={helpImages[currentSlide].Component} 
                        alt={helpImages[currentSlide].alt}
                        className="w-full h-full object-contain max-h-[70vh]"
                        style={{ 
                          maxWidth: '100%',
                          width: 'auto',
                          height: 'auto'
                        }}
                      />
                    </div>

                    {/* Navigation container */}
                    <div className="relative w-full bg-white/90 backdrop-blur-sm p-4">
                      {/* Navigation dots */}
                      <div className="flex justify-center space-x-3 mb-2">
                        {helpImages.map((_, index) => (
                          <button
                            key={index}
                            onClick={() => setCurrentSlide(index)}
                            className={`w-2.5 h-2.5 rounded-full transition-all duration-200 ${
                              currentSlide === index 
                                ? 'bg-black w-4' // Make active dot slightly wider
                                : 'bg-gray-300'
                            }`}
                          />
                        ))}
                      </div>

                      {/* Navigation arrows - Made more touch-friendly */}
                      <div className="flex justify-between px-2">
                        <button
                          id="prev-upload-example-button"
                          onClick={prevSlide}
                          className="p-3 hover:bg-gray-100 rounded-full transition-colors"
                          aria-label="Previous slide"
                        >
                          <svg 
                            className="w-6 h-6 pointer-events-none" 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                          >
                            <path 
                              strokeLinecap="round" 
                              strokeLinejoin="round" 
                              strokeWidth={2} 
                              d="M15 19l-7-7 7-7" 
                            />
                          </svg>
                        </button>
                        <button
                          id="next-upload-example-button"
                          onClick={nextSlide}
                          className="p-3 hover:bg-gray-100 rounded-full transition-colors"
                          aria-label="Next slide"
                        >
                          <svg 
                            className="w-6 h-6 pointer-events-none" 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                          >
                            <path 
                              strokeLinecap="round" 
                              strokeLinejoin="round" 
                              strokeWidth={2} 
                              d="M9 5l7 7-7 7" 
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>

              {/* Image Preview Grid */}
              {images.length > 0 && (
                <div className="image-preview space-y-4">
                  <p className="text-sm font-medium text-gray-700">{images.length} / 10 images selected</p>
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                    {images.map((file, index) => (
                      <div key={index} className="relative group aspect-square">
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`Preview ${index + 1}`}
                          className="w-full h-full object-cover rounded-lg"
                          style={{ transform: `rotate(${rotations[index] || 0}deg)` }}
                        />
                        <button
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveImage(index);
                          }}
                          className="absolute top-2 right-2 bg-red-500 text-white p-1.5 rounded-full transition-opacity sm:p-1 md:p-1.5 lg:p-2"
                          id={`remove_outfit_image_in_upload_page`}
                        >
                          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                        <div className="absolute bottom-2 right-2 flex gap-1">
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              rotateImage(index);
                            }}
                            className="p-1.5 bg-white/80 backdrop-blur-sm rounded-full hover:bg-white transition-colors"
                            aria-label="Rotate image"
                            id="rotate-outfit"
                          >
                            <svg className="w-4 h-4 pointer-events-none" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Error Messages */}
              {errors.imageLimit && (
                <p className="text-red-500 text-sm">{errors.imageLimit}</p>
              )}
              {errors.general && (
                <p className="text-red-500 text-sm">{errors.general}</p>
              )}
              {errors.subscription && (
                <div className="p-4 bg-red-50 border border-red-200 rounded-lg">
                  <p className="text-red-700 mb-3">{errors.subscription}</p>
                  <Button 
                    onClick={() => window.location.href = '/pricing'} 
                    className="bg-gradient-to-r from-blue-500 to-purple-600 text-white px-4 py-2 rounded-full hover:from-purple-500 hover:to-blue-600 transition-all duration-300"
                    id="upgrade-plan-button-in-outfit-upload-page"
                  >
                    Upgrade Plan
                  </Button>
                </div>
              )}

              {/* Submit Button */}
              <button
                onClick={handleSubmit}
                disabled={images.length === 0 || !hasSubscription || pendingSubmit}
                className="upload-button sticky bottom-8 left-0 right-0 flex justify-center z-40 w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white px-6 py-3 rounded-full hover:from-purple-500 hover:to-blue-600 disabled:from-gray-400 disabled:to-gray-500 transition-all duration-300 shadow-sm flex items-center justify-center"
                id="submit_outfit_upload"
              >
                {pendingSubmit ? 'Confirming...' : 'Upload Outfits'}
              </button>
            </>
          ) : (
            <div className="bg-white p-8 rounded-lg shadow-lg">
              <Loader />
            </div>
          )}
        </div>
      </div>

      {/* Confirmation modal */}
      <Dialog open={showRotationConfirm} onOpenChange={(open) => {
        if (!open) {
          setPendingSubmit(false); // Reset the confirming state
        }
        setShowRotationConfirm(open);
      }}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogTitle>Confirm Image Orientation</DialogTitle>
          <DialogDescription>
            Please make sure all images are in the correct orientation before uploading.
          </DialogDescription>
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="dont-show-again"
                checked={dontShowAgain}
                onCheckedChange={(checked) => setDontShowAgain(checked)}
              />
              <label
                htmlFor="dont-show-again"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Don't show again
              </label>
            </div>
            <div className="flex justify-end space-x-2">
              <Button
                id="image-orientation-cancel-button"
                variant="outline"
                onClick={() => {
                  setShowRotationConfirm(false);
                  setPendingSubmit(false);
                }}
              >
                Cancel
              </Button>
              <Button 
                id="image-orientation-confirm-button"
                className="bg-blue-500 hover:bg-blue-600" 
                onClick={handleConfirmUpload}
              >
                Upload
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default OutfitUpload;
