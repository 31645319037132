import React, { useState, useEffect, useCallback, memo } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { client } from '../axiosClient';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import ReactMarkdown from 'react-markdown';
import Joyride from 'react-joyride';
import { useStartTour } from '../hooks/startTour';

// Reusable Stats Card Component - Displays individual statistics with animations
const StatCard = memo(({ title, value, className, onClick, id }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className={`relative p-4 rounded-xl border-2 border-dashed ${className}`}
    onClick={onClick}
    id={id}
  >
    <h3 className="text-lg font-semibold mb-2 pointer-events-none">{title}</h3>
    <p className="text-3xl font-bold pointer-events-none">{value}</p>
  </motion.div>
));

StatCard.displayName = 'StatCard';

// Reusable Action Button Component - Navigation buttons with icons
const ActionButton = memo(({ to, icon, text, className, id }) => (
  <Link 
    to={to}
    className={`${className} flex flex-col items-center justify-center p-6 bg-gray-200 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-105 `}
    id={id}
  >
    <div className="text-4xl mb-2 pointer-events-none">{icon}</div>
    <span className="text-sm font-medium text-gray-700 pointer-events-none">{text}</span>
  </Link>
));

ActionButton.displayName = 'ActionButton';

// Reusable Outfit Card Component - Displays outfit combinations with positioning logic
const OutfitCard = memo(({ combination, onClick }) => {
  // Memoized style calculation function for outfit item positioning
  const getItemStyle = useCallback((outfit, index, totalItems) => {
    const baseStyle = {
      position: 'absolute',
      transition: 'transform 0.2s, z-index 0.2s',
    };

    const category = outfit.category?.toLowerCase() || '';

    // Helper function for slight position variation
    const vary = (base, range = 5) => base + (Math.sin(index * 3.14) * range);

    // Calculate size based on category and total items
    let width, height, zIndex, left, top;

    switch (category) {
      case 'outerwear':
        width = 45;
        height = 55;
        zIndex = 20;
        left = vary(75);
        top = vary(25);
        break;
      case 'top':
        width = 40;
        height = 45;
        zIndex = 15;
        left = vary(25);
        top = vary(30);
        break;
      case 'bottom':
        width = 40;
        height = 45;
        zIndex = 10;
        left = vary(25);
        top = vary(70);
        break;
      case 'dress':
        width = 40;
        height = 60;
        zIndex = 20;
        left = vary(20);
        top = vary(65);
        break;
      case 'shoes':
        width = 35;
        height = 30;
        zIndex = 20;
        left = vary(50);
        top = vary(85);
        break;
      case 'bag':
        width = 35;
        height = 40;
        zIndex = 25;
        left = vary(80);
        top = vary(75);
        break;
      case 'hat':
        width = 30;
        height = 30;
        zIndex = 25;
        left = vary(50);
        top = vary(20);
        break;
      default:
        width = 30;
        height = 35;
        zIndex = 20;
        left = vary(50);
        top = vary(50);
    }

    // Adjust sizes based on total items
    const sizeMultiplier = totalItems <= 3 ? 1.8 : 
                          totalItems <= 4 ? 1.6 : 
                          totalItems <= 5 ? 1.4 : 1.2;
    
    width *= sizeMultiplier;
    height *= sizeMultiplier;

    // Add slight rotation for natural look
    const rotate = (Math.sin(index * 2.5) * 15);

    return {
      ...baseStyle,
      width: `${width}%`,
      height: `${height}%`,
      left: `${left}%`,
      top: `${top}%`,
      zIndex,
      transform: `translate(-50%, -50%) rotate(${rotate}deg)`,
    };
  }, []);

  return (
    <div 
      className="block bg-white rounded-lg shadow-md p-4 transition-transform duration-300 transform hover:shadow-xl hover:scale-105 cursor-pointer"
      onClick={onClick}
    >
      {/* Outfit collage */}
      <div className="relative h-[300px] bg-gray-50 rounded-lg overflow-hidden">
        {combination.items.map((outfit, index) => (
          <div
            key={outfit.id}
            style={getItemStyle(outfit, index, combination.items.length)}
            className="hover:z-50 hover:scale-105 transition-all duration-200"
          >
            <img
              src={outfit.image_url}
              alt={`${outfit.category || 'Outfit item'} ${index + 1}`}
              className="w-full h-full object-contain rounded-lg"
              loading="lazy"
            />
          </div>
        ))}
      </div>

      {/* Prompt and date */}
      <div className="mt-4 space-y-2">
        <p className="text-sm text-gray-700 line-clamp-2">{combination.prompt}</p>
        <p className="text-sm text-gray-500">
          {new Date(combination.created_at).toLocaleDateString()}
        </p>
      </div>
    </div>
  );
});

OutfitCard.displayName = 'OutfitCard';

// Main Dashboard Component - Primary layout and functionality
const Dashboard = () => {
  // State Management Section
  const { runTour, steps, startTour, config, pushToDataLayer } = useStartTour('dashboard');
  const [recentCombinations, setRecentCombinations] = useState([]);
  const [statistics, setStatistics] = useState({
    total_clothes: 0,
    total_favorites: 0,
    closet_value: 0
  });
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOutfit, setSelectedOutfit] = useState(null);

  // Tour Guide Initialization Section
  useEffect(() => {
    const hasSeenTour = localStorage.getItem('hasSeenDashboardTour');
    if (!hasSeenTour) {
      startTour();
      localStorage.setItem('hasSeenDashboardTour', 'false');
    }
  }, [startTour]);

  const handleJoyrideCallback = (data) => {
    const { action, index, status, step, type } = data;

    // Track tour interactions
    if (type === 'step:after' || type === 'tour:end' || type === 'tour:start') {
      pushToDataLayer(
        action,
        status,
        index + 1,
        step?.target || 'body'
      );
    }

    // Handle tour completion or skip
    if (status === 'finished' || status === 'skipped') {
      localStorage.setItem('hasSeenDashboardTour', 'true');
    }
  };

  // Data Fetching Section
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch both statistics and combinations in parallel
        const [statsResponse, combinationsResponse] = await Promise.all([
          client.get('/api/user/statistics/'),
          client.get('/api/combination-history/')
        ]);

        setStatistics(statsResponse.data.statistics);
        setRecentCombinations(combinationsResponse.data.slice(0, 4)); // Get only the 4 most recent combinations
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value);
  };

  return (
    <div className="container mx-auto px-4 py-8 space-y-8">
      {/* Tour Guide Integration */}
      <Joyride
        {...config}
        run={runTour}
        steps={steps}
        callback={handleJoyrideCallback}
      />
      
      {/* Stats Section - Display user statistics */}
      <div className="statistics-container grid grid-cols-1 sm:grid-cols-3 gap-4">
        <StatCard 
          id="total-clothes-stat-card"
          title="Total Clothes" 
          value={statistics.total_clothes}
          className="total-clothes-card bg-pink-50 border-pink-200 cursor-pointer"
          onClick={() => window.location.href = '/my-closet'}
        />
        <StatCard 
          id="total-favorites-stat-card"
          title="Favorites" 
          value={statistics.total_favorites}
          className="favorites-card bg-purple-50 border-purple-200 cursor-pointer"
          onClick={() => window.location.href = '/favorites'}
        />
        <StatCard 
          id="closet-value-stat-card"
          title="Closet Value" 
          value={formatCurrency(statistics.closet_value)}
          className="closet-value-card bg-blue-50 border-blue-200"
        />
      </div>

      {/* Action Buttons Section - Quick access to main features */}
      <div className="grid grid-cols-2 gap-4">
        <ActionButton
          to="/generate"
          id="dashboard-generate-outfit-button"
          icon={
            <svg className="generate-outfit-button w-10 h-10 text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>
          }
          text="Generate Outfits"
          className="generate-outfit-button"
        />
        <ActionButton
          to="/upload"
          id="dashboard-upload-clothes-button"
          icon={
            <svg className="upload-clothes-button w-10 h-10 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg>
          }
          text="Upload Clothes"
          className="upload-clothes-button"
        />
      </div>

      {/* Recent Combinations Section - Display latest generated outfits */}
      <div className="recent-combinations-container space-y-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">Recent Outfits</h2>
          <Link 
            to="/generate" 
            className="text-sm text-blue-600 hover:text-blue-800 transition-colors"
            id="dashboard-generate-more-link"
          >
            Generate More →
          </Link>
        </div>
        
        {isLoading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {[...Array(4)].map((_, index) => (
              <div 
                key={index}
                className="bg-gray-100 rounded-lg h-[400px] animate-pulse"
              />
            ))}
          </div>
        ) : recentCombinations.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {recentCombinations.map((combination) => (
              <OutfitCard
                key={combination.id}
                combination={combination}
                onClick={() => setSelectedOutfit(combination)}
              />
            ))}
          </div>
        ) : (
          <div className="text-center py-12 bg-gray-50 rounded-lg">
            <p className="text-gray-500">No outfits generated yet</p>
            <Link 
              to="/generate"
              className="mt-4 inline-block text-blue-600 hover:text-blue-800"
              id="dashboard-generate-first-outfit-link"
            >
              Generate your first outfit →
            </Link>
          </div>
        )}
      </div>

      {/* Generated Outfit Details Modal - Show detailed outfit information */}
      <Transition appear show={!!selectedOutfit} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => setSelectedOutfit(null)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between items-start">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Generated Outfit Details
                    </Dialog.Title>
                    <button onClick={() => setSelectedOutfit(null)} className="text-gray-500 hover:text-gray-700"
                    id="dashboard-outfit-details-close-button">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                  <hr className="my-4" />
                  {selectedOutfit && (
                    <div className="space-y-4">
                      <div>
                        <h4 className="font-medium text-gray-700">Prompt</h4>
                        <div className="prose prose-sm max-w-none text-gray-600">
                          <ReactMarkdown>{selectedOutfit.prompt}</ReactMarkdown>
                        </div>
                      </div>
                      <div>
                        <h4 className="font-medium text-gray-700">Generated At</h4>
                        <p className="text-gray-600">
                          {new Date(selectedOutfit.created_at).toLocaleDateString()}
                        </p>
                      </div>
                      <div>
                        <h4 className="font-medium text-gray-700">Suggestion</h4>
                        <div className="prose prose-sm max-w-none text-gray-600">
                          <ReactMarkdown>{selectedOutfit.suggestion_text}</ReactMarkdown>
                        </div>
                      </div>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default memo(Dashboard); 