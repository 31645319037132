import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { client } from '../axiosClient';
import { useToast } from './ui/use-toast';
import { Button } from './ui/button';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import EditOutfitModal from './EditOutfitModal';

// Reusable Outfit Card Component - Displays outfit combinations with positioning logic
const OutfitCard = ({ combination, onClick }) => {
  const getItemStyle = useCallback((outfit, index, totalItems) => {
    const baseStyle = {
      position: 'absolute',
      transition: 'transform 0.2s, z-index 0.2s',
    };

    const category = outfit.category_name?.toLowerCase() || '';

    // Helper function for slight position variation
    const vary = (base, range = 5) => base + (Math.sin(index * 3.14) * range);

    // Calculate size and position based on category
    let width, height, zIndex, left, top;

    switch (category) {
      case 'outerwear':
        width = 45;
        height = 55;
        zIndex = 20;
        left = vary(75);
        top = vary(25);
        break;
      case 'top':
        width = 40;
        height = 45;
        zIndex = 15;
        left = vary(25);
        top = vary(30);
        break;
      case 'bottom':
        width = 40;
        height = 45;
        zIndex = 10;
        left = vary(25);
        top = vary(75);
        break;
      case 'dress':
        width = 40;
        height = 60;
        zIndex = 15;
        left = vary(20);
        top = vary(65);
        break;
      case 'shoes':
        width = 35;
        height = 35;
        zIndex = 20;
        left = vary(50);
        top = vary(85);
        break;
      case 'bag':
        width = 35;
        height = 40;
        zIndex = 25;
        left = vary(80);
        top = vary(75);
        break;
      case 'hat':
        width = 30;
        height = 30;
        zIndex = 25;
        left = vary(50);
        top = vary(20);
        break;
      default:
        width = 30;
        height = 35;
        zIndex = 20;
        left = vary(50);
        top = vary(50);
    }

    // Adjust sizes based on total items
    const sizeMultiplier = totalItems <= 3 ? 1.8 : 
                          totalItems <= 4 ? 1.6 : 
                          totalItems <= 5 ? 1.4 : 1.2;
    
    width *= sizeMultiplier;
    height *= sizeMultiplier;

    // Add slight rotation for natural look
    const rotate = (Math.sin(index * 2.5) * 15);

    return {
      ...baseStyle,
      width: `${width}%`,
      height: `${height}%`,
      left: `${left}%`,
      top: `${top}%`,
      zIndex,
      transform: `translate(-50%, -50%) rotate(${rotate}deg)`,
    };
  }, []);

  return (
    <div 
      onClick={onClick}
      id="generated_outfit_with_this_item_card"
      className="bg-white rounded-2xl shadow-sm p-4 cursor-pointer hover:shadow-xl transition-shadow overflow-hidden border border-gray-100"
    >
      <div className="relative w-full overflow-hidden pointer-events-none" style={{ paddingBottom: '100%' }}>
        {combination.outfits.map((outfit, index) => (
          <div
            key={outfit.id}
            style={getItemStyle(outfit, index, combination.outfits.length)}
            className="absolute overflow-hidden hover:z-50 hover:scale-105 transition-all duration-200 pointer-events-none"
          >
            <img
              src={outfit.image_url}
              alt={outfit.description || 'Outfit item'}
              className="w-full h-full object-contain rounded-lg pointer-events-none"
              loading="lazy"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

// Main Outfit Detail Component - Displays detailed information about a single outfit
const OutfitDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [loading, setLoading] = useState(true);
  const [outfit, setOutfit] = useState(null);
  const [generatedOutfits, setGeneratedOutfits] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedOutfit, setSelectedOutfit] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const fetchOutfitDetails = async () => {
      try {
        const response = await client.get(`/api/outfits/${id}/detail/`);
        setOutfit(response.data.outfit);
        setGeneratedOutfits(response.data.generated_outfits);
      } catch (error) {
        console.error('Error fetching outfit details:', error);
        toast({
          title: "Error",
          description: "Failed to load outfit details",
          variant: "destructive",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchOutfitDetails();
  }, [id, toast]);

  const handleDelete = async () => {
    try {
      await client.delete(`/api/outfits/${id}/`);
      toast({
        title: "Success",
        description: "Item deleted successfully",
      });
      navigate('/my-closet');
    } catch (error) {
      console.error('Error deleting outfit:', error);
      toast({
        title: "Error",
        description: "Failed to delete item",
        variant: "destructive",
      });
    }
  };

  const handleEdit = () => {
    setShowEditModal(true);
  };

  const handleOutfitUpdated = (updatedOutfit) => {
    setOutfit(updatedOutfit);
    toast({
      title: "Success",
      description: "Outfit updated successfully",
    });
  };

  // Description section - see more and see less
  const renderDescription = (description) => {
    const MAX_LENGTH = 50; // Adjust this value to change when "see more" appears
    
    if (!description) return null;
    
    if (description.length <= MAX_LENGTH || showFullDescription) {
      return (
        <span className="font-light flex-1 p-1">
          {description}
          {description.length > MAX_LENGTH && (
            <button 
              onClick={() => setShowFullDescription(false)} 
              className="ml-1 text-blue-500 hover:text-blue-700 font-medium"
              id="see_less_button"
            >
              see less
            </button>
          )}
        </span>
      );
    }
    
    return (
      <span className="font-light flex-1 p-1">
        {description.slice(0, MAX_LENGTH)}...
        <button 
          onClick={() => setShowFullDescription(true)} 
          className="ml-1 text-blue-500 hover:text-blue-700 font-medium"
          id="see_more_button"
        >
          see more
        </button>
      </span>
    );
  };

  // Add this function to handle mouse movement for desktop zoom
  const handleMouseMove = (e) => {
    if (!containerRef.current || !imageRef.current || scale === 1) return;

    const rect = containerRef.current.getBoundingClientRect();
    const x = (e.clientX - rect.left) / rect.width;
    const y = (e.clientY - rect.top) / rect.height;

    const moveX = (x - 0.7) * 2 * (scale - 1) * -300;
    const moveY = (y - 0.7) * 2 * (scale - 1) * -300;

    setPosition({ x: moveX, y: moveY });
  };

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="text-center">Loading...</div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Back Navigation Section */}
      <button
        onClick={() => navigate('/my-closet')}
        className="flex items-center gap-2 text-gray-600 hover:text-gray-900 mb-6"
        id="back_to_closet_button"
      >
        <ArrowLeftIcon className="h-5 w-5" />
        <span>Back to My Closet</span>
      </button>

      {/* Selected Item Details Section */}
      <div className="flex flex-col md:flex-row md:items-start gap-6 mb-8">
        {/* Image Preview Container */}
        <div className="w-full md:w-48 md:flex-shrink-0">
          <div 
            className="bg-white rounded-2xl shadow-sm overflow-hidden cursor-pointer"
            onClick={() => setShowImageModal(true)}
            id="outfit_image_preview"
          >
            <img
              src={outfit?.image_url}
              alt={outfit?.description || 'Outfit item'}
              className="w-full aspect-square object-contain"
            />
          </div>
        </div>

        {/* Item Details Container */}
        <div className="flex-grow w-full">
          {/* Tags and Categories Section */}
          <div className="flex flex-wrap gap-2 mb-4">
            {outfit?.category && (
              <span className="px-3 py-1 bg-purple-200 rounded-full text-sm text-gray-700">
                {outfit.category.name.charAt(0).toUpperCase() + outfit.category.name.slice(1)}
              </span>
            )}
            {outfit?.color && (() => {
              let colorClass;
              switch (outfit.color.name.toLowerCase()) {
                case 'red':
                  colorClass = 'bg-red-300 text-red-800';
                  break;
                case 'blue':
                  colorClass = 'bg-blue-300 text-blue-800';
                  break;
                case 'green':
                  colorClass = 'bg-green-300 text-green-800';
                  break;
                case 'yellow':
                  colorClass = 'bg-yellow-300 text-yellow-800';
                  break;
                case 'orange':
                  colorClass = 'bg-orange-300 text-orange-800';
                  break;
                case 'purple':
                  colorClass = 'bg-purple-300 text-purple-800';
                  break;
                case 'pink':
                  colorClass = 'bg-pink-300 text-pink-800';
                  break;
                case 'brown':
                  colorClass = 'bg-[#8B4513] text-[#FFFFFF]';
                  break;
                case 'black':
                  colorClass = 'bg-gray-800 text-white';
                  break;
                default:
                  colorClass = 'bg-gray-300 text-gray-800';
              }
              return (
                <span className={`px-3 py-1 ${colorClass} rounded-full text-sm`}>
                  {outfit.color.name.toLowerCase()}
                </span>
              );
            })()}
            {outfit?.season?.map((season) => {
              let seasonClass;
              switch (season.name.toLowerCase()) {
                case 'summer':
                  seasonClass = 'bg-yellow-200 text-yellow-800';
                  break;
                case 'spring':
                  seasonClass = 'bg-green-200 text-green-800';
                  break;
                case 'fall':
                  seasonClass = 'bg-orange-200 text-orange-800';
                  break;
                case 'winter':
                  seasonClass = 'bg-blue-200 text-blue-800';
                  break;
                default:
                  seasonClass = 'bg-gray-200 text-gray-800';
              }
              return (
                <span key={season.name} className={`px-3 py-1 ${seasonClass} rounded-full text-sm`}>
                  {season.name.charAt(0).toUpperCase() + season.name.slice(1)}
                </span>
              );
            })}
            {outfit?.activity?.map((activity) => (
              <span key={activity.name} className="px-3 py-1 bg-gray-100 rounded-full text-sm text-gray-700">
                {activity.name.charAt(0).toUpperCase() + activity.name.slice(1)}
              </span>
            ))}
          </div>

          {/* Price, Brand and Description Section - Better spacing on mobile */}
          <div className="mb-6 space-y-3">
            {outfit?.brand?.name ? (
              <div className="flex flex-wrap items-center gap-2">
                <span className="min-w-[60px] font-medium">Brand:</span>
                <span className="font-light">{outfit.brand.name.toUpperCase()}</span>
              </div>
            ) : (
              <div className="flex flex-wrap items-center gap-2">
                <span className="font-medium min-w-[60px]">Brand:</span>
                <span onClick={handleEdit} className="cursor-pointer text-blue-500 hover:text-blue-700" id="add_brand_button">Add brand</span>
              </div>
            )}
            {outfit?.price !== null && outfit?.price !== undefined ? (
              <div className="flex flex-wrap items-center gap-2">
                <span className="font-medium min-w-[60px]">Price:</span>
                <span className="font-light">
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                  }).format(outfit.price)}
                </span>
              </div>
            ) : (
              <div className="flex flex-wrap items-center gap-2">
                <span className="text-gray-600 min-w-[60px]">Price:</span>
                <span onClick={handleEdit} className="cursor-pointer text-blue-500 hover:text-blue-700" id="add_price_button">Add price</span>
              </div>
            )}
            {outfit?.description ? (
              <div className="flex flex-wrap items-start gap-2">
                <span className="font-medium min-w-[60px] mt-1">Description:</span>
                {renderDescription(outfit.description)}
              </div>
            ) : (
              <div className="flex flex-wrap items-center gap-2">
                <span className="font-medium min-w-[60px]">Description:</span>
                <span onClick={handleEdit} className="cursor-pointer text-blue-500 hover:text-blue-700" id="add_description_button">Add description</span>
              </div>
            )}
          </div>

          {/* Action Buttons Section */}
          <div className="flex gap-3">
            <button 
              onClick={handleEdit}
              className="w-12 h-12 md:w-10 md:h-10 bg-blue-500 hover:bg-blue-700 text-white rounded-full flex items-center justify-center transition-colors duration-300"
              id="edit_outfit_button"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6 md:w-5 md:h-5 pointer-events-none">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" className="pointer-events-none"/>
              </svg>
            </button>
            <button
              onClick={() => setShowDeleteModal(true)}
              className="w-12 h-12 md:w-10 md:h-10 bg-gray-800 rounded-full flex items-center justify-center transition-colors duration-300 ease-in-out hover:bg-red-500 focus:outline-none"
              id="delete_single_outfit_button"
            >
              <svg 
                viewBox="0 0 448 512" 
                className="w-5 h-5 md:w-4 md:h-4 text-white pointer-events-none"
              >
                <path 
                  className="pointer-events-none"
                  fill="currentColor" 
                  d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Generated Outfits Grid Section */}
      <div>
        <h2 className="text-xl md:text-2xl font-bold mb-6">Generated Outfits with this item</h2>
        {generatedOutfits.length === 0 ? (
          <p className="text-gray-500">No generated outfits found with this item.</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6">
            {generatedOutfits.map((genOutfit) => (
              <OutfitCard
                key={genOutfit.id}
                combination={genOutfit}
                onClick={() => setSelectedOutfit(genOutfit)}
                id={`generated_outfit_with_this_item_card_click`}
              />
            ))}
          </div>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      <Transition appear show={showDeleteModal} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => setShowDeleteModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between items-start">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Delete item?
                    </Dialog.Title>
                    <button onClick={() => setShowDeleteModal(false)} className="text-gray-500 hover:text-gray-700">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                  <hr className="my-2" />
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      It will also be removed from generated outfits.<br/> <br/> Which cause missing parts in the generated outfits.<br/> <br/> This can't be undone.
                    </p>
                  </div>

                  <div className="mt-4 flex gap-4 justify-end">
                    <Button
                      variant="outline"
                      onClick={() => setShowDeleteModal(false)}
                      id="cancel_delete_single_outfit_button"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="destructive"
                      onClick={() => {
                        handleDelete();
                        setShowDeleteModal(false);
                      }}
                      id="confirm_delete_single_outfit_button"
                    >
                      Delete
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Generated Outfit Details Modal */}
      <Transition appear show={!!selectedOutfit} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => setSelectedOutfit(null)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between items-start">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Generated Outfit Details
                    </Dialog.Title>
                    <button onClick={() => setSelectedOutfit(null)} className="text-gray-500 hover:text-gray-700">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                  <hr className="my-4" />
                  {selectedOutfit && (
                    <div className="space-y-4">
                      <div>
                        <h4 className="font-medium text-gray-700">Prompt</h4>
                        <div className="prose prose-sm max-w-none text-gray-600">
                          <ReactMarkdown>{selectedOutfit.prompt}</ReactMarkdown>
                        </div>
                      </div>
                      <div>
                        <h4 className="font-medium text-gray-700">Generated At</h4>
                        <p className="text-gray-600">
                          {new Date(selectedOutfit.created_at).toLocaleDateString()}
                        </p>
                      </div>
                      <div>
                        <h4 className="font-medium text-gray-700">Suggestion</h4>
                        <div className="prose prose-sm max-w-none text-gray-600">
                          <ReactMarkdown>{selectedOutfit.suggestion_text}</ReactMarkdown>
                        </div>
                      </div>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Edit Outfit Modal Section */}
      <EditOutfitModal
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        outfit={outfit}
        onOutfitUpdated={handleOutfitUpdated}
      />

      {/* Image Zoom Modal Section */}
      <Transition appear show={showImageModal} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => setShowImageModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <div className="absolute right-2 top-2 z-10">
                    <button
                      onClick={() => {
                        setScale(1);
                        setPosition({ x: 0, y: 0 });
                        setShowImageModal(false);
                      }}
                      className="rounded-full bg-white/80 p-2 text-gray-800 hover:bg-white hover:text-gray-900 transition-all"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                  <div 
                    ref={containerRef}
                    className="relative aspect-square w-full overflow-hidden"
                    onMouseMove={handleMouseMove}
                    onMouseEnter={() => setScale(2)}
                    onMouseLeave={() => {
                      setScale(1);
                      setPosition({ x: 0, y: 0 });
                    }}
                  >
                    <motion.div
                      ref={imageRef}
                      className="w-full h-full"
                      animate={{
                        scale: scale,
                        x: position.x,
                        y: position.y,
                      }}
                      transition={{ type: "tween", duration: 0.2 }}
                      drag={scale > 1}
                      dragConstraints={{
                        top: -200 * (scale - 1),
                        bottom: 200 * (scale - 1),
                        left: -200 * (scale - 1),
                        right: 200 * (scale - 1)
                      }}
                      dragElastic={0}
                      dragMomentum={false}
                      style={{
                        touchAction: scale > 1 ? "none" : "auto"
                      }}
                    >
                      <img
                        src={outfit?.image_url}
                        alt={outfit?.description || 'Outfit item'}
                        className="w-full h-full object-contain select-none"
                        draggable="false"
                      />
                    </motion.div>
                  </div>
                  {/* Zoom controls for mobile */}
                  <div className="absolute bottom-4 right-4 flex gap-2">
                    <button
                      onClick={() => {
                        setScale(prev => {
                          const newScale = Math.max(1, prev - 0.5);
                          if (newScale === 1) setPosition({ x: 0, y: 0 });
                          return newScale;
                        });
                      }}
                      className="rounded-full bg-white/80 p-2 text-gray-800 hover:bg-white hover:text-gray-900 transition-all"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
                      </svg>
                    </button>
                    <button
                      onClick={() => setScale(prev => Math.min(3, prev + 0.5))}
                      className="rounded-full bg-white/80 p-2 text-gray-800 hover:bg-white hover:text-gray-900 transition-all"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                      </svg>
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default OutfitDetail; 