import React, { useState, useEffect, useCallback, memo, useMemo } from 'react';
import { client } from '../axiosClient';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Joyride from 'react-joyride';
import { useStartTour } from '../hooks/startTour';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "./ui/dialog";
import demoImage1 from '../assets/demoSuggestion1.webp';
import demoImage2 from '../assets/demoSuggestion2.webp';
import demoImage3 from '../assets/demoSuggestion3.webp';
import demoImage4 from '../assets/demoSuggestion4.webp';
import demoImage5 from '../assets/demoSuggestion5.webp';
import { useToast } from './ui/use-toast';

// Add this CSS at the top of the file, after the imports
const styles = `
  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  @keyframes pulse-opacity {
    0% { opacity: 0.4; }
    50% { opacity: 0.8; }
    100% { opacity: 0.4; }
  }
  .animate-pulse-opacity {
    animation: pulse-opacity 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
`;

// Add the styles to the document
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

// Memoized Loading Spinner Component
const LoadingSpinner = memo(() => (
  <span className="flex items-center justify-center">
    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
    Generating
  </span>
));

LoadingSpinner.displayName = 'LoadingSpinner';

// Memoized Outfit Image Component
const OutfitImage = memo(({ outfit, isLocked, onLockToggle }) => (
  <div className="relative group aspect-[3/4]">
    <img
      src={outfit.image_url}
      alt={`Suggested outfit ${outfit.id}`}
      className="w-full h-full object-cover rounded-lg shadow-sm transition-transform duration-300 group-hover:scale-[1.02]"
      loading="lazy"
    />
    <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-10 transition-all duration-300 rounded-lg"></div>
    <button
      id={`lock_outfit`}
      onClick={() => onLockToggle(outfit.id)}
      className={`lock-button lock-button absolute top-2 right-2 p-2 rounded-full ${
        isLocked
          ? 'bg-green-500 text-white'
          : 'bg-white text-gray-600'
      } shadow-md hover:scale-110 transition-all duration-200`}
      aria-label={isLocked ? 'Unlock outfit' : 'Lock outfit'}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 pointer-events-none"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        {isLocked ? (
          // Closed lock icon
          <path
            fillRule="evenodd"
            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
            clipRule="evenodd"
          />
        ) : (
          // Open lock icon
          <svg viewBox="0 0 330 330">
            <path d="M15,160c8.284,0,15-6.716,15-15V85c0-30.327,24.673-55,55-55c30.327,0,55,24.673,55,55v45h-25 c-8.284,0-15,6.716-15,15v170c0,8.284,6.716,15,15,15h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15H170V85 c0-46.869-38.131-85-85-85S0,38.131,0,85v60C0,153.284,6.716,160,15,160z" />
          </svg>
        )}
      </svg>
    </button>
  </div>
));

OutfitImage.displayName = 'OutfitImage';

// Memoized Navigation Controls Component
const NavigationControls = memo(({ canShowPrevious, canShowNext, onPrevious, onNext, currentIndex, totalSuggestions }) => (
  <div className="flex items-center gap-2 text-sm">
    <button
      id="previous-suggestion"
      onClick={onPrevious}
      disabled={!canShowPrevious}
      className="px-3 py-1.5 text-xs sm:text-sm bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
    >
      Previous
    </button>
    <div className="flex justify-center font-semibold text-gray-700">
      {currentIndex + 1} / {totalSuggestions}
    </div>
    <button
      id="next-suggestion"
      onClick={onNext}
      disabled={!canShowNext}
      className="px-3 py-1.5 text-xs sm:text-sm bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
    >
      Next
    </button>
  </div>
));

NavigationControls.displayName = 'NavigationControls';

// Sample prompts data structure
const SAMPLE_PROMPTS = {
  'Casual & Everyday Wear': [
    "I need a stylish yet comfortable outfit for a casual brunch with friends this weekend. It should be suitable for warm weather and have a relaxed vibe.",
    "Suggest a cozy yet chic outfit for running errands on a chilly autumn afternoon. Prioritize comfort and layering.",
    "I'm heading to a summer picnic in the park. Please suggest a light and breezy outfit that suits warm weather and outdoor activities."
  ],
  'Work & Business Attire': [
    "I need a professional yet fashionable outfit for a corporate meeting in a formal office setting. Keep it business-appropriate but stylish.",
    "Suggest a smart-casual outfit for a business lunch. It should strike a balance between professional and relaxed.",
    "I work in a creative industry with a semi-casual dress code. Suggest an outfit that is professional but allows for personal style expression."
  ],
  'Evening & Party Wear': [
    "I'm attending a cocktail party in the evening. Please suggest a trendy yet elegant outfit for the occasion.",
    "I need an outfit for a romantic dinner date. It should be stylish, flattering, and season-appropriate.",
    "Suggest a bold and glamorous outfit for a night out with friends at a high-end rooftop bar."
  ],
  'Sports & Activewear': [
    "I'm going for a morning yoga session followed by a casual coffee meetup. Suggest an outfit that's functional and stylish.",
    "Recommend an outfit for an outdoor hiking trip. It should be practical, comfortable, and suitable for unpredictable weather.",
    "I have a gym session planned, followed by a quick grocery run. Suggest an athleisure outfit that looks good outside the gym too."
  ],
  'Seasonal & Weather-Specific': [
    "It's a rainy spring day, and I need an outfit that keeps me dry and stylish while commuting.",
    "It's a freezing winter morning, and I need a warm yet fashionable outfit for a casual day out.",
    "The summer heat is intense. Suggest an airy, lightweight outfit that helps me stay cool while looking chic."
  ],
  'Special Events & Holidays': [
    "I'm attending a beach wedding. Suggest an elegant but breathable outfit that fits the occasion.",
    "It's Christmas Eve, and I need a cozy but festive outfit for a family gathering.",
    "I'm celebrating my birthday at a trendy rooftop venue. Suggest a statement outfit that makes me stand out."
  ],
  'Themed & Unique Requests': [
    "I'm going to a 90s-themed party. Suggest a stylish outfit inspired by that era.",
    "I need an outfit that perfectly matches my red sneakers. Keep it casual but put-together.",
    "I love monochrome looks. Suggest an all-black outfit that's stylish and versatile for different occasions."
  ]
};

// Sample Prompts Component
const SamplePrompts = memo(({ onSelectPrompt }) => {
  const getRandomPrompt = useCallback((prompts) => {
    const randomIndex = Math.floor(Math.random() * prompts.length);
    return prompts[randomIndex];
  }, []);

  return (
    <div className="mt-2 sm:mt-4">
      <p className="text-xs sm:text-sm text-gray-600 mb-1.5 sm:mb-2">Get inspired instantly →</p>
      <div className="relative">
        <div className="overflow-x-auto scrollbar-hide pb-2 -mx-4 px-4 sm:mx-0 sm:px-0">
          <div className="flex gap-1.5 sm:gap-2 sm:flex-wrap whitespace-nowrap">
            {Object.entries(SAMPLE_PROMPTS).map(([category, prompts]) => (
              <button
                key={category}
                onClick={(e) => {
                  e.preventDefault();
                  onSelectPrompt(getRandomPrompt(prompts));
                }}
                type="button"
                className="text-[10px] sm:text-xs px-2 sm:px-3 py-1 sm:py-1.5 bg-white border border-gray-200 rounded-full hover:bg-gray-50 hover:border-gray-300 transition-all duration-200 shadow-sm flex-shrink-0"
                id={`sample_prompt_${category.toLowerCase().replace(/\s+/g, '_')}`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>
        <div className="absolute right-0 top-0 bottom-2 w-12 pointer-events-none sm:hidden" 
          style={{
            background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)'
          }}
        >
          <div className="absolute right-0 top-0 bottom-0 w-4 animate-pulse-opacity">
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-4 w-4 text-blue-800 absolute top-1/2 -translate-y-1/2 right-1"
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M9 5l7 7-7 7" 
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
});

SamplePrompts.displayName = 'SamplePrompts';

// Main Component
const OutfitSuggestion = memo(() => {
  // State Management Section
  const [prompt, setPrompt] = useState('');
  const [suggestion, setSuggestion] = useState('');
  const [suggestedOutfits, setSuggestedOutfits] = useState([]);
  const [initialLoading, setInitialLoading] = useState(false);
  const [newSuggestionLoading, setNewSuggestionLoading] = useState(false);
  const [error, setError] = useState('');
  const [showNotes, setShowNotes] = useState(false);
  const [currentCombinationId, setCurrentCombinationId] = useState(null);
  const [isLiked, setIsLiked] = useState(false);
  const [lockedOutfits, setLockedOutfits] = useState(new Set());
  const [userId, setUserId] = useState(null);
  const [allSuggestions, setAllSuggestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { runTour, steps, startTour, config, pushToDataLayer } = useStartTour('outfitSuggestion');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [closetSize, setClosetSize] = useState(0);
  const [showDemo, setShowDemo] = useState(false);
  const { toast } = useToast();
  // Demo data with combinationId
  const demoSuggestion = {
    suggestion: "Here's a stylish outfit combination perfect for your request:\n\n" +
      "1. A classic white button-down shirt for a timeless look\n" +
      "2. Dark wash slim-fit jeans for a modern silhouette\n" +
      "3. Brown leather dress boots adding sophistication\n" +
      "4. A navy blazer for a polished finish\n" +
      "5. Silver minimalist accessories to complete the look",
    outfits: [
      { id: 'demo1', image_url: demoImage1 },
      { id: 'demo2', image_url: demoImage2 },
      { id: 'demo3', image_url: demoImage3 },
      { id: 'demo4', image_url: demoImage4 },
      { id: 'demo5', image_url: demoImage5 },
    ],
    prompt: "I need a smart casual outfit for a dinner date",
    combinationId: 'demo-combination'
  };

  // Memoized handlers
  const handleResize = useCallback(() => {
    // Add resize handler if needed
  }, []);

  const fetchUserId = useCallback(async () => {
    try {
      const response = await client.get('/api/user/');
      setUserId(response.data.user.id);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, []);

  // Clear suggestion history and unliked combinations
  const clearSuggestionHistory = useCallback(async () => {
    try {
      // Clear unliked combinations from the backend
      await client.post('/api/clear-generation-algorithm-records/');
      
      // Clear local storage for current user only
      if (userId) {
        localStorage.removeItem(`outfitSuggestions_${userId}`);
        localStorage.removeItem(`currentSuggestionIndex_${userId}`);
      }
      
      setAllSuggestions([]);
      setCurrentIndex(0);
      setSuggestion('');
      setSuggestedOutfits([]);
      setPrompt('');
      setCurrentCombinationId(null);
      setIsLiked(false);
      setLockedOutfits(new Set());
    } catch (error) {
      console.error('Error clearing unliked combinations:', error);
      setError('Failed to clear suggestion history');
    }
  }, [userId]);

  // Get outfit suggestion from API and update state
  const getSuggestion = useCallback(async (isNewSuggestion = false) => {
    if (isNewSuggestion) {
      setNewSuggestionLoading(true);
    } else {
      setInitialLoading(true);
    }
    setError('');
    
    try {
      // Make API call to get outfit suggestion with locked outfits
      const response = await client.post('/api/outfit-suggestion/', { 
        prompt,
        locked_outfit_ids: Array.from(lockedOutfits)
      });

      // Check if there are no suggested outfits
      if (!response.data.suggested_outfits || response.data.suggested_outfits.length === 0) {
        setError(response.data.suggestion || 'Unable to generate outfit suggestion. Please try again.');
        return;
      }
      
      // Store new suggestion in history with its prompt
      const newSuggestion = {
        suggestion: response.data.suggestion,
        outfits: response.data.suggested_outfits,
        prompt: prompt,
        combinationId: response.data.combination_id,
        isLiked: false
      };
      
      setAllSuggestions(prev => [...prev, newSuggestion]);
      setSuggestion(response.data.suggestion);
      setSuggestedOutfits(response.data.suggested_outfits);
      setCurrentIndex(allSuggestions.length);
      setCurrentCombinationId(response.data.combination_id);
      setIsLiked(false);
    } catch (error) {
      console.error('Error getting outfit suggestion:', error);
      // Show the actual error message from the API if available
      const errorMessage = error.response?.data?.error || error.response?.data?.detail || 'Failed to generate suggestion. Please try again later.';
      setError(errorMessage);
    } finally {
      if (isNewSuggestion) {
        setNewSuggestionLoading(false);
      } else {
        setInitialLoading(false);
      }
    }
  }, [prompt, lockedOutfits, allSuggestions.length]);

  // Handle initial suggestion request for new prompt
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    await getSuggestion(false);
  }, [getSuggestion]);

  // Get alternative suggestion for current prompt
  const handleNewSuggestion = useCallback(async () => {
    await getSuggestion(true);
  }, [getSuggestion]);

   // Navigate to previous suggestion in history
  const handlePreviousSuggestion = useCallback(() => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      const previousSuggestion = allSuggestions[newIndex];
      setCurrentIndex(newIndex);
      setSuggestedOutfits(previousSuggestion.outfits);
      setSuggestion(previousSuggestion.suggestion);
      setPrompt(previousSuggestion.prompt);
      setCurrentCombinationId(previousSuggestion.combinationId);
      setIsLiked(previousSuggestion.isLiked);
    }
  }, [currentIndex, allSuggestions]);

  // Navigate to next suggestion in history
  const handleNextSuggestion = useCallback(() => {
    if (currentIndex < allSuggestions.length - 1) {
      const newIndex = currentIndex + 1;
      const nextSuggestion = allSuggestions[newIndex];
      setCurrentIndex(newIndex);
      setSuggestedOutfits(nextSuggestion.outfits);
      setSuggestion(nextSuggestion.suggestion);
      setPrompt(nextSuggestion.prompt);
      setCurrentCombinationId(nextSuggestion.combinationId);
      setIsLiked(nextSuggestion.isLiked);
    }
  }, [currentIndex, allSuggestions]);

  const handleLikeToggle = useCallback(async () => {
    if (!currentCombinationId) return;

    try {
      const response = await client.post(`/api/add-remove-favorite-outfit/${currentCombinationId}/`);
      const isLiked = response.data.liked;

      setIsLiked(isLiked);
      if (isLiked) {
        toast({
          title: "Outfit added to favorites",
          variant: "default",
        });
      } else {
        toast({
          title: "Outfit removed from favorites",
          variant: "default",
        });
      }
      
      // Update the like state in allSuggestions
      setAllSuggestions(prev => prev.map((suggestion, index) => {
        if (index === currentIndex) {
          return {
            ...suggestion,
            isLiked: isLiked
          };
        }
        return suggestion;
      }));
    } catch (error) {
      console.error('Error toggling like:', error);
      setError('Failed to update like status');
    }
  }, [currentCombinationId, currentIndex]);

  const handleLockToggle = useCallback((outfitId) => {
    setLockedOutfits(prevLocked => {
      const newLocked = new Set(prevLocked);
      if (newLocked.has(outfitId)) {
        newLocked.delete(outfitId);
      } else {
        newLocked.add(outfitId);
      }
      return newLocked;
    });
  }, []);

  // Memoized values
  const isViewingCurrent = useMemo(() => currentIndex === allSuggestions.length - 1, 
    [currentIndex, allSuggestions.length]);
  
  const canShowPrevious = useMemo(() => currentIndex > 0, [currentIndex]);
  
  const canShowNext = useMemo(() => currentIndex < allSuggestions.length - 1, 
    [currentIndex, allSuggestions.length]);

  // Add function to fetch closet size
  const fetchClosetSize = useCallback(async () => {
    try {
      const response = await client.get('/api/outfits/');
      setClosetSize(response.data.length);
    } catch (error) {
      console.error('Error fetching closet size:', error);
    }
  }, []);

  // Modify clearSuggestionHistory to use confirmation
  const handleClearHistory = useCallback(() => {
    setIsConfirmModalOpen(false);
    clearSuggestionHistory();
  }, [clearSuggestionHistory]);

  const handleSamplePromptSelect = useCallback((selectedPrompt) => {
    setPrompt(selectedPrompt);
  }, []);

  // Effects
  useEffect(() => {
    fetchUserId();
  }, [fetchUserId]);

  useEffect(() => {
    if (userId) {
      const savedSuggestions = localStorage.getItem(`outfitSuggestions_${userId}`);
      const savedIndex = localStorage.getItem(`currentSuggestionIndex_${userId}`);
      
      if (savedSuggestions) {
        setAllSuggestions(JSON.parse(savedSuggestions));
        setCurrentIndex(savedIndex ? parseInt(savedIndex) : 0);
      }
    } else {
      setAllSuggestions([]);
      setCurrentIndex(0);
      setSuggestion('');
      setSuggestedOutfits([]);
      setPrompt('');
      setCurrentCombinationId(null);
      setIsLiked(false);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      localStorage.setItem(`outfitSuggestions_${userId}`, JSON.stringify(allSuggestions));
      localStorage.setItem(`currentSuggestionIndex_${userId}`, currentIndex.toString());
    }
  }, [allSuggestions, currentIndex, userId]);

  useEffect(() => {
    if (allSuggestions.length > 0 && userId) {
      const currentSuggestion = allSuggestions[currentIndex];
      setSuggestion(currentSuggestion.suggestion);
      setSuggestedOutfits(currentSuggestion.outfits);
      setPrompt(currentSuggestion.prompt);
      setCurrentCombinationId(currentSuggestion.combinationId);
      setIsLiked(currentSuggestion.isLiked);
    }
  }, [allSuggestions, currentIndex, userId]);

  useEffect(() => {
    const checkLikeStatus = async () => {
      if (!currentCombinationId) return;

      try {
        const response = await client.get(`/api/check-like-status-at-suggestion-page/${currentCombinationId}/`);
        setIsLiked(response.data.liked);
        
        setAllSuggestions(prev => prev.map((suggestion, index) => {
          if (index === currentIndex) {
            return {
              ...suggestion,
              isLiked: response.data.liked
            };
          }
          return suggestion;
        }));
      } catch (error) {
        console.error('Error checking like status:', error);
      }
    };

    checkLikeStatus();
  }, [currentCombinationId, currentIndex]);

  useEffect(() => {
    const hasSeenTour = localStorage.getItem('hasSeenOutfitSuggestionTour');
    if (!hasSeenTour) {
      startTour();
      setShowDemo(true);
      // Set demo data including combinationId
      setSuggestion(demoSuggestion.suggestion);
      setSuggestedOutfits(demoSuggestion.outfits);
      setPrompt(demoSuggestion.prompt);
      setShowNotes(true);
      setCurrentCombinationId(demoSuggestion.combinationId);
      localStorage.setItem('hasSeenOutfitSuggestionTour', 'false');
    }
  }, [startTour]);

  useEffect(() => {
    fetchClosetSize();
  }, [fetchClosetSize]);

  const handleJoyrideCallback = (data) => {
    const { action, index, status, step, type } = data;

    // Track tour interactions
    if (type === 'step:after' || type === 'tour:end' || type === 'tour:start') {
      pushToDataLayer(
        action,
        status,
        index + 1,
        step?.target || 'body'
      );
    }

    if (status === 'finished' || status === 'skipped') {
      localStorage.setItem('hasSeenOutfitSuggestionTour', 'true');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4 sm:p-6 space-y-6 sm:space-y-8">
      <Joyride
        {...config}
        run={runTour}
        steps={steps}
        callback={handleJoyrideCallback}
      />
      <div className="max-w-6xl mx-auto mt-10 px-4">
        <h2 className="text-2xl font-semibold mb-6" id="generate_outfits_button">Generate Outfits</h2>
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
          <div className="flex-1 w-full">
            {closetSize === 0 ? (
              <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-lg">
                <div className="flex items-start">
                  <svg className="w-5 h-5 text-red-400 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-red-800">No Items in Closet</p>
                    <p className="mt-1 text-xs text-red-700">Please add items to your closet to generate outfits.</p>
                  </div>
                </div>
              </div>
            ) : closetSize < 30 && (
              <div className="mb-4 p-3 bg-yellow-50 border border-yellow-200 rounded-lg">
                <div className="flex items-start">
                  <svg className="w-5 h-5 text-yellow-400 mt-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-yellow-800">Insufficient Items</p>
                    <p className="mt-1 text-xs text-yellow-700">Add more items to get more accurate and diverse suggestions. Make sure you have enough tops, bottoms and shoes. Otherwise, the recommendations may not be satisfactory. Currently {closetSize}/30 items in your closet.</p>
                  </div>
                </div>
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className="relative flex flex-col sm:block gap-2">
                <textarea
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  placeholder="I need an outfit for the first date..."
                  rows={Math.min(3, Math.max(1, prompt.split('\n').length))}
                  className="prompt-textarea w-full p-3 sm:p-4 border border-gray-200 rounded-[24px] shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all resize-none sm:pr-[140px]"
                  style={{
                    minHeight: '120px',
                    height: 'auto',
                    maxHeight: '150px'
                  }}
                  id="suggestion_prompt_input"
                />
                <button
                  type="submit"
                  disabled={!prompt || initialLoading || newSuggestionLoading || closetSize === 0}
                  className="generate-button w-full sm:w-auto sm:absolute sm:right-2 sm:top-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white px-6 py-2 rounded-full hover:from-purple-500 hover:to-blue-600 disabled:from-gray-400 disabled:to-gray-500 transition-all duration-300 shadow-sm"
                  id="generate_outfit_button"
                >
                  {initialLoading ? <LoadingSpinner /> : 'Generate'}
                </button>
              </div>
            </form>
            <SamplePrompts onSelectPrompt={handleSamplePromptSelect} />
          </div>          
        </div>

        {/* Error display */}
        {error && (
          <div className="mt-4 p-4 bg-red-50 border border-red-200 rounded-lg">
            <p className="text-red-600 whitespace-pre-line">{error}</p>
          </div>
        )}
        {/* Suggestion display section */}
        {(suggestion || allSuggestions.length > 0 || showDemo) && (
          <div className="mt-4 sm:mt-6 bg-white rounded-xl shadow-md overflow-hidden">
            <div className="p-3 sm:p-6">
              {/* Header with navigation and like controls */}
              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-3 mb-4">
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => setShowNotes(!showNotes)}
                    className="show-hide-notes-button text-xs sm:text-sm text-blue-600 hover:text-blue-700 flex items-center gap-1"
                    id="show_hide_notes_button"
                  >
                    {showNotes ? 'Hide Notes' : 'Show Notes'}
                    <svg
                      className={`w-3 h-3 sm:w-4 sm:h-4 transition-transform duration-200 ${showNotes ? 'rotate-180' : ''}`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                </div>
                <div className="flex items-center gap-2">
                  {/* Like button - Show in both demo and normal mode */}
                  {(currentCombinationId || showDemo) && (
                    <button
                      onClick={showDemo ? () => setIsLiked(!isLiked) : handleLikeToggle}
                      className="like-button focus:outline-none transform transition-transform duration-200 hover:scale-110"
                      id={`${isLiked ? "dislike_outfit_in_suggestion_page" : "like_outfit_in_suggestion_page"}`}
                    >
                      <svg
                        className={`w-6 h-6 sm:w-8 sm:h-8 ${isLiked ? 'text-red-500 fill-current' : 'text-gray-400 stroke-current'} pointer-events-none`}
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={isLiked ? "0" : "2"}
                        fill={isLiked ? "currentColor" : "none"}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                        />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
              
              {/* Outfit images grid */}
              {suggestedOutfits.length > 0 && (
                <>
                  <div className="suggested-outfits grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-2 sm:gap-4 mb-4 sm:mb-6" id="suggested_outfits_grid">
                    {suggestedOutfits.map((outfit, index) => (
                      <OutfitImage
                        key={index}
                        outfit={outfit}
                        isLocked={lockedOutfits.has(outfit.id)}
                        onLockToggle={handleLockToggle}
                      />
                    ))}
                  </div>

                  {/* AI suggestion notes */}
                  {showNotes && (
                    <div className="suggestion-notes bg-gray-50 p-3 sm:p-4 rounded-lg mb-4 text-sm sm:text-base">
                      <ReactMarkdown 
                        children={suggestion} 
                        remarkPlugins={[remarkGfm]}
                        className="prose max-w-none text-sm sm:text-base"
                      />
                      <br />
                      <p className="text-sm sm:text-base"> <strong>Prompt:</strong> {prompt}</p>
                    </div>
                  )}

                  {/* Navigation and controls section */}
                  <div className="flex flex-col sm:flex-row justify-between items-center gap-3">
                    <div className="flex items-center gap-2 w-full sm:w-auto justify-between sm:justify-start">
                      {!showDemo && (
                        <NavigationControls
                          canShowPrevious={canShowPrevious}
                          canShowNext={canShowNext}
                          onPrevious={handlePreviousSuggestion}
                          onNext={handleNextSuggestion}
                          currentIndex={currentIndex}
                          totalSuggestions={allSuggestions.length}
                        />
                      )}
                      
                      {/* Clear History button - Show in both demo and normal mode */}
                      {(allSuggestions.length > 0 || showDemo) && (
                        <Dialog open={isConfirmModalOpen} onOpenChange={setIsConfirmModalOpen}>
                          <DialogTrigger asChild>
                            <button id="clear_history_button" className="clear-history-button px-2 py-1 text-xs bg-gradient-to-r from-red-500 to-red-600 text-white rounded-full hover:from-red-600 hover:to-red-700 transition-all duration-300 flex items-center justify-center gap-1 whitespace-nowrap shadow-sm">
                              <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="12" 
                                height="12" 
                                viewBox="0 0 24 24" 
                                fill="none" 
                                stroke="currentColor" 
                                strokeWidth="2" 
                                strokeLinecap="round" 
                                strokeLinejoin="round"
                                className="sm:w-4 sm:h-4 pointer-events-none"
                              >
                                <path d="M3 6h18"></path>
                                <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                                <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
                              </svg>
                              <span className="hidden sm:inline pointer-events-none">Clear History</span>
                            </button>
                          </DialogTrigger>
                          <DialogContent className="sm:max-w-md w-[95vw] sm:w-full mx-auto">
                            <DialogHeader>
                              <DialogTitle>Clear Suggestion History</DialogTitle>
                            </DialogHeader>
                            <div className="py-4">
                              <p className="text-gray-600 text-sm sm:text-base">
                                This action cannot be undone.
                              </p>
                            </div>
                            <div className="flex justify-end gap-4">
                              <button
                                id="clear-history-modal-cancel-button"
                                onClick={() => setIsConfirmModalOpen(false)}
                                className="px-3 py-1.5 text-xs sm:text-sm text-gray-600 hover:text-gray-800 transition-colors"
                              >
                                Cancel
                              </button>
                              <button
                                id="clear-history-modal-confirm-button"
                                onClick={handleClearHistory}
                                className="px-3 py-1.5 text-xs sm:text-sm bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
                              >
                                Clear History
                              </button>
                            </div>
                          </DialogContent>
                        </Dialog>
                      )}
                    </div>
                    <button
                      id="regenerate-button"
                      onClick={handleNewSuggestion}
                      disabled={initialLoading || newSuggestionLoading}
                      className="regenerate-button w-full sm:w-auto flex items-center justify-center gap-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white px-4 sm:px-6 py-2 rounded-full hover:from-purple-500 hover:to-blue-600 disabled:from-gray-400 disabled:to-gray-500 transition-all duration-300 shadow-sm text-sm"
                    >
                      <svg 
                        className={`w-3 h-3 sm:w-4 sm:h-4 ${newSuggestionLoading ? 'animate-spin' : ''} pointer-events-none`}
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                      </svg>
                      {newSuggestionLoading ? 'Regenerating...' : 'Regenerate'}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

OutfitSuggestion.displayName = 'OutfitSuggestion';

export default OutfitSuggestion;
