import './App.css';
import './tailwind.css';
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { client } from './axiosClient';
import { Toaster } from './components/ui/toaster';

import Layout from './components/Layout';
import OutfitUpload from './components/OutfitUpload';
import OutfitGallery from './components/OutfitGallery copy';
import OutfitSuggestion from './components/OutfitSuggestion';
import LoginRegister from './components/LoginRegister';
import Profile from './components/Profile';
import ForgotPassword from './components/ForgotPassword';
import ResetPasswordConfirm from './components/ResetPasswordConfirm';
import Dashboard from './components/Dashboard';
import Favorites from './components/Favorites';
import OutfitDetail from './components/OutfitDetail';
import Feedback from './components/Feedback';
import Pricing from './components/Pricing';
import WelcomeOnboarding from './components/WelcomeOnboarding';

function AppContent() {
  const [currentUser, setCurrentUser] = useState(null);
  const [refreshGallery, setRefreshGallery] = useState(0);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const triggerGalleryRefresh = useCallback(() => {
    setRefreshGallery(prev => prev + 1);
  }, []);

  const handleUserUpdate = useCallback((updatedUser) => {
    setCurrentUser(updatedUser);
  }, []);

  const checkAuthStatus = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('access_token') || localStorage.getItem('access_token');
    const refreshToken = urlParams.get('refresh_token') || localStorage.getItem('refresh_token');

    if (accessToken && refreshToken) {
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('refresh_token', refreshToken);
      client.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      // Remove the tokens from the URL
      window.history.replaceState({}, document.title, window.location.pathname);

      try {
        const [userResponse, statsResponse] = await Promise.all([
          client.get("/api/user/"),
          client.get("/api/user/statistics/")
        ]);

        console.log('User data:', userResponse.data);
        console.log('Stats data:', statsResponse.data);

        const userData = {
          ...userResponse.data.user,
          hasSubscription: statsResponse.data.has_subscription,
          subscriptionPlan: statsResponse.data.subscription_plan
        };

        setCurrentUser(userData);

        // Set user email in data layer for GTM
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'user_email': userData.email
        });

      } catch (error) {
        console.error("Error fetching user data:", error);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  if (loading) {
    return <div>Loading...</div>;
  }

  console.log('Current user state:', currentUser);
  console.log('Has completed onboarding:', currentUser?.has_completed_onboarding);

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    delete client.defaults.headers.common['Authorization'];
    setCurrentUser(null);
  };

  const isWelcomePage = location.pathname === '/welcome';
  
  // If user is logged in but hasn't completed onboarding, show WelcomeOnboarding
  if (currentUser && !currentUser.has_completed_onboarding) {
    return (
      <WelcomeOnboarding 
        onComplete={() => {
          console.log('Onboarding completed');
          setCurrentUser({
            ...currentUser,
            has_completed_onboarding: true
          });
        }} 
      />
    );
  }

  // For welcome page, don't wrap in Layout
  if (isWelcomePage) {
    return (
      <>
        <Routes>
          <Route path="/welcome" element={<WelcomeOnboarding />} />
        </Routes>
        <Toaster />
      </>
    );
  }

  return (
    <>
      <Layout 
        isLoggedIn={!!currentUser} 
        onLogout={handleLogout}
        username={currentUser?.first_name || currentUser?.email}
        avatar={currentUser?.avatar}
        user={currentUser}
      >
        <Routes>
          {currentUser ? (
            // Authenticated routes
            <>
              <Route path="/" element={<Dashboard />} />
              <Route path="/my-closet" element={<OutfitGallery refreshTrigger={refreshGallery} />} />
              <Route path="/generate" element={<OutfitSuggestion />} />
              <Route path="/upload" element={<OutfitUpload onUploadSuccess={triggerGalleryRefresh} />} />
              <Route path="/profile" element={
                <Profile 
                  user={currentUser}
                  onUserUpdate={handleUserUpdate}
                />
                } />
              <Route path="/favorites" element={<Favorites />} />
              <Route path="/my-closet/:id" element={<OutfitDetail />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/login" element={<Navigate to="/" />} />
            </>
          ) : (
            // Unauthenticated routes
            <>
              <Route path="/login" element={<LoginRegister setCurrentUser={setCurrentUser} />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:uid/:token" element={<ResetPasswordConfirm />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
        </Routes>
      </Layout>
      <Toaster />
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
