import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { useToast } from './ui/use-toast';
import { client } from '../axiosClient';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Checkbox } from './ui/checkbox';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";

const EditOutfitModal = ({ isOpen, onClose, outfit, onOutfitUpdated }) => {
  const { toast } = useToast();
  const [editedOutfit, setEditedOutfit] = useState(null);
  const [colorInput, setColorInput] = useState('');
  const [colorOpen, setColorOpen] = useState(false);
  const [colors, setColors] = useState([]);
  const [brandInput, setBrandInput] = useState('');
  const [brandOpen, setBrandOpen] = useState(false);
  const [brands, setBrands] = useState([]);
  const [priceInput, setPriceInput] = useState('');
  const dropdownRef = useRef(null);
  
  const categories = [
    "top", "bottom", "dress", "shoes", "outerwear", 
    "swimwear", "bag", "hat", "other"
  ];
  
  const seasons = ["spring", "summer", "fall", "winter"];
  const activities = ["beach", "casual", "everyday", "formal", "outdoor", "party", "pool", "sports", "weekend", "work", "workout"];

  useEffect(() => {
    if (outfit) {
      setEditedOutfit({
        ...outfit,
        season: outfit.season?.map(s => s.name) || [],
        activity: outfit.activity?.map(a => a.name) || [],
      });
      setColorInput(outfit.color?.name ? outfit.color.name.toLowerCase() : '');
      setBrandInput(outfit.brand?.name ? outfit.brand.name.toUpperCase() : '');
      setPriceInput(outfit.price || '');
      console.log('Initial outfit data:', outfit);
    }
  }, [outfit]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch both color and brand data
        const [colorResponse, brandResponse] = await Promise.all([
          client.get('/api/colors/'),
          client.get('/api/brands/')
        ]);
        setColors(colorResponse.data);
        setBrands(brandResponse.data);
      } catch (error) {
        console.error('Error fetching colors and brands:', error);
        toast({
          title: "Error",
          description: "Failed to load colors and brands",
          variant: "destructive",
        });
      }
    };

    if (isOpen) {
      fetchData();
    }
  }, [isOpen, toast]);

  const handleChange = useCallback((name, value) => {
    setEditedOutfit(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleMultiSelectChange = useCallback((name, itemName, checked) => {
    setEditedOutfit(prev => {
      const currentItems = prev[name] || [];
      const updatedItems = checked 
        ? [...currentItems, itemName]
        : currentItems.filter(item => item !== itemName);
      console.log(`Updated ${name}:`, updatedItems);
      return { ...prev, [name]: updatedItems };
    });
  }, []);

  const handleColorSelect = useCallback((color) => {
    const lowercaseColor = color.toLowerCase();
    setEditedOutfit(prev => ({ 
      ...prev, 
      color: { name: lowercaseColor } 
    }));
    setColorInput(lowercaseColor);
    setColorOpen(false);
  }, []);

  const handleBrandSelect = useCallback((brand) => {
    const uppercaseBrand = brand.toUpperCase();
    setEditedOutfit(prev => ({ 
      ...prev, 
      brand: { name: uppercaseBrand } 
    }));
    setBrandInput(uppercaseBrand);
    setBrandOpen(false);
  }, []);

  const filteredColors = colors.filter(color =>
    color.name.toLowerCase().includes(colorInput.toLowerCase())
  );

  const filteredBrands = brands.filter(brand =>
    brand.name.toUpperCase().includes(brandInput.toUpperCase())
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!editedOutfit) return;

    const formData = new FormData();
    
    formData.append('category', editedOutfit.category?.name || '');
    formData.append('color', colorInput.toLowerCase());
    formData.append('brand', brandInput.toUpperCase());
    if (priceInput !== '') {
      formData.append('price', priceInput);
    }
    
    if (editedOutfit.season?.length > 0) {
      editedOutfit.season.forEach(season => {
        formData.append('season[]', season);
      });
    }
    
    if (editedOutfit.activity?.length > 0) {
      editedOutfit.activity.forEach(activity => {
        formData.append('activity[]', activity);
      });
    }
    
    formData.append('description', editedOutfit.description || '');

    console.log('Submitting form data:', {
      category: editedOutfit.category?.name,
      color: colorInput,
      brand: brandInput,
      price: priceInput,
      seasons: editedOutfit.season,
      activities: editedOutfit.activity,
      description: editedOutfit.description
    });

    try {
      const response = await client.put(
        `/api/outfits/${editedOutfit.id}/edit/`, 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log('Server response:', response.data);
      onOutfitUpdated(response.data);
      onClose();
      toast({
        title: "Success",
        description: "Outfit updated successfully",
      });
    } catch (error) {
      console.error('Error updating outfit:', error);
      toast({
        title: "Error",
        description: error.response?.data?.error || "Failed to update outfit",
        variant: "destructive",
      });
    }
  };

  if (!editedOutfit) return null;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 mb-4"
                >
                  Edit Outfit
                </Dialog.Title>
                <form onSubmit={handleSubmit} className="space-y-4">
                  {/* Category */}
                  <div className="space-y-2">
                    <Label>Category</Label>
                    <Select
                      value={editedOutfit.category?.name}
                      onValueChange={(value) => handleChange('category', { name: value })}
                      id="category_select"
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select category" />
                      </SelectTrigger>
                      <SelectContent>
                        {categories.map((category) => (
                          <SelectItem key={category} value={category} id={`category_edit_option_${category}`}>
                            {category}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>

                  {/* Brand */}
                  <div className="space-y-2">
                    <Label>Brand</Label>
                    <Popover open={brandOpen} onOpenChange={setBrandOpen}>
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          role="combobox"
                          aria-expanded={brandOpen}
                          className="w-full justify-between"
                          id="brand_input_button"
                        >
                          {brandInput || "Search/Add brand..."}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-full p-0" id="brand_popover">
                        <Command>
                          <CommandInput
                            placeholder="Search/Add brand..."
                            value={brandInput}
                            onValueChange={(value) => setBrandInput(value.toUpperCase())}
                            id="brand_search_input"
                          />
                          <CommandEmpty id="brand_empty_message">
                            Add "{brandInput}" as a new brand
                          </CommandEmpty>
                          <CommandGroup id="brand_suggestions">
                            {filteredBrands.map((brand) => (
                              <CommandItem
                                key={brand.id}
                                value={brand.name}
                                onSelect={() => handleBrandSelect(brand.name)}
                                id={`brand_option_${brand.id}`}
                              >
                                {brand.name.toUpperCase()}
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </div>

                  {/* Price */}
                  <div className="space-y-2">
                    <Label>Price</Label>
                    <Input
                      type="number"
                      step="0.01"
                      min="0"
                      value={priceInput}
                      onChange={(e) => setPriceInput(e.target.value)}
                      placeholder="Enter price"
                      id="price_input"
                    />
                  </div>

                  {/* Color */}
                  <div className="space-y-2">
                    <Label>Color</Label>
                    <Popover open={colorOpen} onOpenChange={setColorOpen}>
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          role="combobox"
                          aria-expanded={colorOpen}
                          className="w-full justify-between"
                          id="color_input_button"
                        >
                          {colorInput || "Search/Add color..."}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent ref={dropdownRef} className="w-full p-0 max-h-60 overflow-y-auto" id="color_popover">
                        <Command>
                          <CommandInput
                            placeholder="Search/Add color..."
                            value={colorInput}
                            onValueChange={(value) => setColorInput(value.toLowerCase())}
                            id="color_search_input"
                          />
                          <CommandEmpty id="color_empty_message">
                            Add "{colorInput}" as a new color
                          </CommandEmpty>
                          <CommandGroup id="color_suggestions">
                            {filteredColors.map((color) => (
                              <CommandItem
                                key={color.id}
                                value={color.name}
                                onSelect={() => handleColorSelect(color.name)}
                                id={`color_option_${color.id}`}
                              >
                                {color.name.toLowerCase()}
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </div>

                  {/* Seasons */}
                  <div className="space-y-2">
                    <Label>Seasons</Label>
                    <div className="grid grid-cols-2 gap-2">
                      {seasons.map((season) => (
                        <div key={season} className="flex items-center space-x-2">
                          <Checkbox
                            id={`season_${season}`}
                            checked={editedOutfit.season?.includes(season)}
                            onCheckedChange={(checked) => 
                              handleMultiSelectChange('season', season, checked)
                            }
                          />
                          <label
                            htmlFor={`season-${season}`}
                            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                          >
                            {season}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Activities */}
                  <div className="space-y-2">
                    <Label>Activities</Label>
                    <div className="grid grid-cols-2 gap-2">
                      {activities.map((activity) => (
                        <div key={activity} className="flex items-center space-x-2">
                          <Checkbox
                            id={`activity-${activity}`}
                            checked={editedOutfit.activity?.includes(activity)}
                            onCheckedChange={(checked) =>
                              handleMultiSelectChange('activity', activity, checked)
                            }
                          />
                          <label
                            htmlFor={`activity-${activity}`}
                            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                          >
                            {activity}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Description */}
                  <div className="space-y-2">
                    <Label>Description</Label>
                    <textarea
                      name="description"
                      value={editedOutfit.description || ''}
                      onChange={(e) => handleChange('description', e.target.value)}
                      className="w-full border-2 border-gray-300 rounded-md p-2"
                      id="description_input"
                    />
                  </div>

                  <div className="flex justify-end space-x-2 mt-4">
                    <Button
                      type="button"
                      variant="outline"
                      onClick={onClose}
                      id="cancel_edit_button"
                    >
                      Cancel
                    </Button>
                    <Button type="submit" id="save_edit_changes_button">
                      Save Changes
                    </Button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditOutfitModal;
