import { useState, useEffect, useCallback } from 'react';
import { tourSteps } from '../lib/joyRide';
import { Joyride } from 'react-joyride';

export const useStartTour = (pageName) => {
    const [runTour, setRunTour] = useState(false);
    const [steps, setSteps] = useState([]);
    
    // Consolidated Joyride configurations
    const [config] = useState({
        // Core options
        continuous: true,
        hideCloseButton: true,
        showProgress: true,
        showSkipButton: true,
        disableOverlayClose: true,
        disableScrolling: false,
        scrollOffset: 300,
        spotlightClicks: false,

        // Styles configuration
        styles: {
            options: {
                beaconSize: 36,
                overlayColor: 'rgba(0, 0, 0, 0.5)',
                backgroundColor: '#fff',
                primaryColor: '#800080',
                textColor: '#2c3e50',
                zIndex: 9999,
            },
            tooltipContainer: {
                textAlign: 'left',
            },
            buttonNext: {
                backgroundColor: '#0000FF',
                fontSize: 13,
            },
            buttonBack: {
                color: '#000000',
                marginRight: 10,
                fontSize: 13,
            },
            buttonSkip: {
                color: '#FF0000',
                backgroundColor: '#fff',
                fontSize: 13,
            }
        },

        // Floater configuration
        floaterProps: {
            disableAnimation: true,
            placement: 'center',
            offset: 20
        },

        // Spotlight configuration
        spotlightPadding: 8,

        // Localization
        locale: {
            back: ({ step }) => `Back`,
            close: ({ step }) => `Close`,
            last: ({ step }) => `Done`,
            next: ({ step }) => `Next`,
            skip: ({ step }) => `Skip`
        }
    });

    // Add function to push data to data layer
    const pushToDataLayer = useCallback((action, status, step, target) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'guided_tour_interaction',
            tour_action: action,
            tour_page: pageName,
            tour_status: status,
            tour_step: step,
            tour_step_target: target
        });
    }, [pageName]);

    useEffect(() => {
        // Get the steps for the current page
        const pageSteps = tourSteps[pageName];
        if (pageSteps) {
            // Convert the object steps into an array format that react-joyride expects
            const formattedSteps = Object.entries(pageSteps).map(([key, step], index) => ({
                ...step,
                placement: step.placement || 'center',
                disableBeacon: step.disableBeacon || false,
                // Add GTM tracking IDs and data attributes
                id: `tour_${pageName}_step_${index + 1}`,
                // Add custom props for the tooltip and buttons
                styles: {
                    options: {
                        ...config.styles.options,
                        arrowColor: '#ffffff',
                    },
                    tooltip: {
                        backgroundColor: '#ffffff',
                        borderRadius: '0.75rem',
                        boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
                        padding: '1.5rem',
                    },
                    tooltipContent: {
                        padding: '0.5rem 0',
                        color: '#1f2937',
                        fontSize: '0.875rem',
                        lineHeight: '1.25rem',
                    },
                    tooltipFooter: {
                        marginTop: '1rem',
                        paddingTop: '0.5rem',
                        borderTop: '1px solid #e5e7eb',
                    },
                },
                tooltipOptions: {
                    'data-tour-step': index + 1,
                    'data-tour-component': pageName,
                },
                locale: {
                    skip: 'Skip',
                    back: 'Back',
                    next: 'Next',
                    last: 'Done'
                },
                // Add IDs for buttons
                buttonProps: {
                    skip: {
                        id: `tour_${pageName}_skip_${index + 1}`,
                        'data-tour-action': 'skip',
                        'data-tour-step': index + 1,
                        className: 'px-5 py-2.5 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 transition-all duration-200 shadow-sm'
                    },
                    back: {
                        id: `tour_${pageName}_back_${index + 1}`,
                        'data-tour-action': 'back',
                        'data-tour-step': index + 1,
                        className: 'px-5 py-2.5 text-sm font-medium text-gray-600 hover:text-gray-800 transition-all duration-200'
                    },
                    next: {
                        id: `tour_${pageName}_next_${index + 1}`,
                        'data-tour-action': 'next',
                        'data-tour-step': index + 1,
                        className: 'px-5 py-2.5 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition-all duration-200 shadow-sm'
                    },
                    close: {
                        id: `tour_${pageName}_close_${index + 1}`,
                        'data-tour-action': 'close',
                        'data-tour-step': index + 1,
                        className: 'px-5 py-2.5 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition-all duration-200 shadow-sm'
                    }
                }
            }));
            setSteps(formattedSteps);
        }
    }, [pageName, config.styles.options]);

    const startTour = () => {
        setRunTour(true);
        // Track tour start
        pushToDataLayer('start', 'started', 1, steps[0]?.target || 'body');
    };

    const endTour = () => {
        setRunTour(false);
        // Track tour end
        pushToDataLayer('end', 'ended', steps.length, steps[steps.length - 1]?.target || 'body');
    };

    return {
        runTour,
        steps,
        startTour,
        endTour,
        setRunTour,
        config,
        pushToDataLayer
    };
};
    