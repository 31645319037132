// Guided tour steps for each page

export const tourSteps = {
    outfitUpload: {
        step1: {
            target: '.upload-area',
            content: (
                <div>
                    <p>Upload your outfit images here and preview them below before proceeding.</p> <br />
                    <a href="/pricing" className="text-sm text-blue-500 mt-2">Upgrade your plan now</a>
                </div>
            ),
            placement: "bottom",
            disableBeacon: true
        },
        step2: {
            target: '.upload-button',
            content: (
                <div>
                    <p>You need to have premium subscription to upload your outfits</p> <br />
                    <a href="/pricing" className="text-sm text-blue-500 mt-2">Upgrade your plan now</a>
                </div>
            ),
            placement: 'top'
        },
        step3: {
            target: '.show-examples',
            content: (
                <div>
                    <p>See examples of how to take a photos, before uploading</p>
                </div>
            ),
            placement: 'bottom'
        }
    },
    dashboard: {
        step1: {
            target: 'body',
            content: (
                <div>
                    <p className="text-center text-2xl font-bold gradient-text bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent">Welcome to Stylegen AI</p>
                    <p className="text-center">AI-powered personal stylist</p><br />
                    <p className="text-center">Before you start, let's take a look how to use Stylegen AI</p>
                </div>
            ),
            placement: 'center',
            disableBeacon: true
        },
        step2: {
            target: '.statistics-container',
            content: (
                <div>
                    <p>View your closet statistics</p>
                </div>
            ),
            placement: 'bottom',
        },
        step3: {
            target: '.total-clothes-card',
            content: (
                <div>
                    <p>View your closet size and click to view your closet</p>
                </div>
            ),
            placement: 'bottom'
        },
        step4: {
            target: '.favorites-card',
            content: (
                <div>
                    <p>View your favorites count and click to view your favorites</p>
                </div>
            ),
            placement: 'bottom'
        },
        step5:{
            target: '.closet-value-card',
            content: (
                <div>
                    <p>Add prices to your outfits to track your closet value</p>
                </div>
            ),
            placement: 'bottom'
        },
        step6: {
            target: '.upload-clothes-button',
            content: (
                <div>
                    <p>Upload your closet before generating outfits with AI</p>
                </div>
            ),
            placement: 'top'
        },
        step7: {
            target: '.generate-outfit-button',
            content: (
                <div>
                    <p>Generate outfits with AI</p>
                </div>
            ),
            placement: 'top'
        },
        step8: {
            target: '.recent-combinations-container',
            content: (
                <div>
                    <p>View your recently generated outfits, and click to view its details</p>
                </div>
            ),
            placement: 'top'
        }
    },
    layout: {
        step1: {
            target: '.side-menu',
            content: (
                <div>
                    <p>Click here to view your profile and manage your subscription</p>
                </div>
            ),
            placement: 'top'
        }
    },
    outfitGallery: {
        step1: {
            target: '.filter-button',
            content: 'Filter your outfits by color, season, activity, brand, and more',
            placement: 'top',
            disableBeacon: true
        },
        step2: {
            target: '.grid-size-selector',
            content: 'Select the grid size to display your closet',
            placement: 'bottom'
        },
        step3: {
            target: '.select-multiple-button',
            content: 'Select multiple outfits to delete',
            placement: 'top'
        },
        step4: {
            target: '.category-filter',
            content: 'Once you upload outfits, more category filters will appear here to filter your closet',
            placement: 'top'
        },
        step5: {
            target: '.closet-empty-view',
            content: 'Uploaded outfits will appear here, click them to view/add details',
            placement: 'top'
        },
        step6: {
            target: '.add-outfit-button',
            content: 'Click here to start adding outfits',
            placement: 'top',
            spotlightClicks: true
        }
    },
    outfitSuggestion: {
        step1: {
            target: '.prompt-textarea',
            content: (
                <div>
                    <p>Enter your prompt here.</p> <br />
                    <p>Generating outfit suggestions requires a premium subscription</p> <br />
                    <a href="/pricing" className="text-md text-blue-500 mt-2">Upgrade your plan now</a>
                </div>
            ),
            placement: 'bottom',
            disableBeacon: true
        },
        step2: {
            target: '.show-hide-notes-button',
            content: 'Show/hide AI stylist\'s suggestion notes',
            placement: 'top'
        },
        step3: {
            target: '.like-button',
            content: 'Like to add this suggestion to your favorites',
            placement: 'top'
        },
        step4: {
            target: '.suggested-outfits',
            content: 'View the suggested items',
            placement: 'top'
        },
        step5: {
            target: '.lock-button',
            content: 'Lock the item(s) and new suggestions will be generated where other items will be chosen to complement locked item(s)',
            placement: 'top'
        },
        step6: {
            target: '.suggestion-notes',
            content: 'See AI stylist\'s suggestion notes',
            placement: 'bottom'
        },
        step7: {
            target: '.clear-history-button',
            content: (
                <div>
                    <p>All suggestions made so far are saved. So you won't see the same suggestions again.</p> <br />
                    <p>However, a history that is not cleared for a long time can reduce your options and you may receive poor quality recommendations</p> <br />
                    <p>Don't worry, you can see past suggestions on the homepage or on the detail page of the outfits, after clearing.</p><br />
                    <p><strong>Pro tip:</strong> Clear to keep your options fresh everyday.</p>
                </div>
            ),
            placement: 'top'
        },
        step8: {
            target: '.regenerate-button',
            content: (
                <div>
                    <p>If you don't like the suggestion,</p><br />
                    <p>Regenerate with the same prompt</p>
                </div>
            ),
            placement: 'top'
        },
        step9: {
            target: 'body',
            content: (
                <div>
                    <p>This was a demo suggestion to help you understand how the feature works</p><br />
                    <p>Generate your own suggestions</p> <br />
                    <a href="/pricing" className="text-md text-blue-500 mt-2">Upgrade your plan now</a>
                </div>
            ),
            placement: 'center'
        },
    },
    profile: {
        step1: {
            target: '.profile-avatar',
            content: 'Update your avatar',
            placement: 'bottom',
            disableBeacon: true
        },
        step2: {
            target: '.subscription-button',
            content: 'Manage your subscription plan and access premium features',
            placement: 'bottom',
        },
        step3: {
            target: '.edit-profile-button',
            content: 'Edit your profile information',
            placement: 'bottom',
        },
        step4: {
            target: '.change-password-button',
            content: 'Update your password for better security',
            placement: 'bottom',
        },
    },
    favorites: {
        step1: {
            target: '.activity-filter',
            content: 'Filter your favorite outfits by activities',
            placement: 'bottom',
            disableBeacon: true
        },
        step2: {
            target: '.favorite-card',
            content: 'View your favorite outfits and filter by activities',
            placement: 'top'
        },
        step3: {
            target: '.edit-activities-button',
            content: 'Edit activities associated with this outfit',
            placement: 'bottom'
        },
        step4: {
            target: '.unlike-button',
            content: 'Remove an outfit from your favorites',
            placement: 'bottom'
        },
        step5: {
            target: 'body',
            content: 'This was a demo favorite card to visualize how the feature works',
            placement: 'center'
        }
    }
};
