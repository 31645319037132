import React, { useState, useEffect, useCallback, memo } from 'react';
import { client } from '../axiosClient';
import { useToast } from './ui/use-toast';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "./ui/dialog";
import { Button } from "./ui/button";
import { Checkbox } from "./ui/checkbox";
import { Label } from "./ui/label";
import { TagIcon } from '@heroicons/react/24/outline';
import Joyride from 'react-joyride';
import { useStartTour } from '../hooks/startTour';
import birthdayPartyImage from '../assets/birthday-party.webp';

// Demo data
const demoFavoriteOutfit = {
  id: 'demo-outfit',
  activities: ['Birthday Party', 'Celebration', 'Party'],
  created_at: new Date().toISOString(),
  outfits: [
    {
      id: 'demo-1',
      image_url: birthdayPartyImage,
      category: 'outfit'
    }
  ]
};

const ActivityFilter = memo(({ activities, selectedActivity, onSelect }) => (
  <div className="flex flex-wrap gap-2 mb-6">
    {activities.map((activity) => (
      <button
        id={`favorite-outfit-activity-filter-${activity}`}
        key={activity}
        onClick={() => onSelect(activity)}
        className={`px-4 py-2 rounded-full border-2 transition-all duration-200 ${
          selectedActivity === activity
            ? 'border-purple-600 bg-purple-600 text-white'
            : 'border-gray-300 hover:border-purple-600'
        }`}
      >
        {activity}
      </button>
    ))}
  </div>
));

ActivityFilter.displayName = 'ActivityFilter';

const EditActivitiesModal = memo(({ isOpen, onClose, combination, onUpdate }) => {
  const { toast } = useToast();
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [availableActivities, setAvailableActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await client.get('/api/activities/');
        setAvailableActivities(response.data);
      } catch (error) {
        console.error('Error fetching activities:', error);
        toast({
          title: "Error",
          description: "Failed to load activities",
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) {
      fetchActivities();
    }
  }, [isOpen, toast]);

  useEffect(() => {
    if (combination) {
      setSelectedActivities(combination.activities || []);
    }
  }, [combination]);

  const handleActivityChange = (activity, checked) => {
    setSelectedActivities(prev => 
      checked 
        ? [...prev, activity]
        : prev.filter(a => a !== activity)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await client.put(
        `/api/favorite-outfits/${combination.id}/`,
        { activities: selectedActivities }
      );
      onUpdate(response.data);
      onClose();
      toast({
        title: "Success",
        description: "Activities updated successfully",
      });
    } catch (error) {
      console.error('Error updating activities:', error);
      toast({
        title: "Error",
        description: "Failed to update activities",
        variant: "destructive",
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Select Activities</DialogTitle>
          <DialogDescription>
            Select the activities that this outfit is associated with.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          {isLoading ? (
            <div className="flex justify-center items-center py-4">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500"></div>
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-4">
              {availableActivities.map((activity) => (
                <div key={activity} className="flex items-center space-x-2">
                  <Checkbox
                    id={`activity-${activity}`}
                    checked={selectedActivities.includes(activity)}
                    onCheckedChange={(checked) => handleActivityChange(activity, checked)}
                  />
                  <Label
                    htmlFor={`activity-${activity}`}
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    {activity}
                  </Label>
                </div>
              ))}
            </div>
          )}
          <div className="flex justify-end space-x-2">
            <Button type="button" variant="outline" onClick={onClose} id="tag-favorite-outfit-modal-cancel-button">
              Cancel
            </Button>
            <Button type="submit" disabled={isLoading} id="tag-favorite-outfit-modal-save-button">
              Save Changes
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
});

EditActivitiesModal.displayName = 'EditActivitiesModal';

const OutfitCard = memo(({ combination, onUnlike }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showEditActivities, setShowEditActivities] = useState(false);

  const handleUnlikeClick = useCallback((e) => {
    e.stopPropagation();
    setShowConfirmation(true);
  }, []);

  const handleEditActivities = useCallback((e) => {
    e.stopPropagation();
    setShowEditActivities(true);
  }, []);

  const handleActivitiesUpdate = useCallback((updatedData) => {
    combination.activities = updatedData.activities;
  }, [combination]);

  // Memoized style calculation function
  const getItemStyle = useCallback((outfit, index, totalItems) => {
    const baseStyle = {
      position: 'absolute',
      transition: 'transform 0.2s, z-index 0.2s',
    };

    const category = outfit.category?.toLowerCase() || '';

    // Group items by category
    const tops = combination.outfits.filter(o => 
      (o.category?.toLowerCase() === 'top' || o.category?.toLowerCase() === 'outerwear')
    ).length;
    const bottoms = combination.outfits.filter(o => 
      o.category?.toLowerCase() === 'bottom' || o.category?.toLowerCase() === 'dress'
    ).length;
    const accessories = combination.outfits.filter(o => 
      o.category?.toLowerCase() === 'bag' || o.category?.toLowerCase() === 'hat' || 
      o.category?.toLowerCase() === 'shoes' || o.category?.toLowerCase() === 'other'
    ).length;

    // Helper function for slight position variation
    const vary = (base, range = 5) => base + (Math.sin(index * 3.14) * range);

    // Calculate size and position based on category
    let width, height, zIndex, left, top;

    switch (category) {
      case 'outerwear':
        width = 45;
        height = 55;
        zIndex = 20;
        left = vary(75);
        top = vary(25);
        break;
      case 'top':
        width = 40;
        height = 45;
        zIndex = 15;
        left = vary(25);
        top = vary(25);
        break;
      case 'bottom':
        width = 40;
        height = 45;
        zIndex = 10;
        left = vary(25);
        top = vary(70);
        break;
      case 'dress':
        width = 40;
        height = 60;
        zIndex = 20;
        left = vary(20);
        top = vary(65);
        break;
      case 'shoes':
        width = 35;
        height = 30;
        zIndex = 25;
        left = vary(50);
        top = vary(85);
        break;
      case 'bag':
        width = 35;
        height = 40;
        zIndex = 24;
        left = vary(80);
        top = vary(75);
        break;
      case 'hat':
        width = 30;
        height = 30;
        zIndex = 25;
        left = vary(50);
        top = vary(20);
        break;
      default:
        width = 30;
        height = 35;
        zIndex = 20;
        left = vary(50);
        top = vary(50);
    }

    // Adjust sizes based on total items
    const sizeMultiplier = totalItems <= 3 ? 1.8 : 
                          totalItems <= 4 ? 1.6 : 
                          totalItems <= 5 ? 1.4 : 1.2;
    
    width *= sizeMultiplier;
    height *= sizeMultiplier;

    // Add slight rotation for natural look
    const rotate = (Math.sin(index * 2.5) * 15); // Rotation between -15 and 15 degrees

    return {
      ...baseStyle,
      width: `${width}%`,
      height: `${height}%`,
      left: `${left}%`,
      top: `${top}%`,
      zIndex,
      transform: `translate(-50%, -50%) rotate(${rotate}deg)`,
    };
  }, [combination.outfits]);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      className="relative bg-white rounded-lg shadow-md p-4 hover:shadow-lg transition-shadow"
    >
      {/* Edit activities button */}
      <button
        onClick={handleEditActivities}
        className="edit-activities-button absolute top-2 left-2 z-50 bg-white rounded-full p-1 shadow-md hover:bg-gray-100 transition-colors"
        id="tag-favorite-outfit-icon"
      >
        <TagIcon className="w-6 h-6 text-gray-500 pointer-events-none" />
      </button>

      {/* Unlike button */}
      <button
        id="unlike-favorite-outfit-icon"
        onClick={handleUnlikeClick}
        className="unlike-button absolute top-2 right-2 z-50 bg-white rounded-full p-1 shadow-md hover:bg-gray-100 transition-colors"
      >
        <svg
          id="unlike-favorite-outfit-icon"
          className="w-6 h-6 text-red-500 fill-current pointer-events-none"
          viewBox="0 0 24 24"
        >
          <path d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
        </svg>
      </button>

      {/* Outfit collage */}
      <div 
        className="relative h-[300px] cursor-pointer bg-gray-50 rounded-lg overflow-hidden"
        id="favorite-outfit-card"
      >
        {combination.outfits.map((outfit, index) => (
          <div
            key={outfit.id}
            style={getItemStyle(outfit, index, combination.outfits.length)}
            className="hover:z-50 hover:scale-105 transition-all duration-200 pointer-events-none"
          >
            <img
              src={outfit.image_url}
              alt={`${outfit.category || 'Outfit item'} ${index + 1}`}
              className="w-full h-full object-contain rounded-lg pointer-events-none"
              loading="lazy"
            />
          </div>
        ))}
      </div>

      {/* Activity tags and date */}
      <div className="mt-4">
        <div className="flex flex-wrap gap-2 mb-2">
          {combination.activities?.map((activity) => (
            <span
              key={activity}
              className="px-2 py-1 text-xs font-medium bg-gray-100 rounded-full"
            >
              {activity}
            </span>
          ))}
        </div>
        <p className="text-sm text-gray-500">
          {new Date(combination.created_at).toLocaleDateString()}
        </p>
      </div>

      {/* Confirmation Modal */}
      {showConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg max-w-sm w-full p-6">
            <h3 className="text-lg font-semibold mb-4">Remove from favorites?</h3>
            <div className="flex justify-end space-x-4">
              <button
                id="unlike-favorite-cancel-button"
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
                onClick={() => setShowConfirmation(false)}
              >
                Cancel
              </button>
              <button
                id="unlike-favorite-confirm-button"
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                onClick={() => {
                  onUnlike(combination.id);
                  setShowConfirmation(false);
                }}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Activities Modal */}
      <EditActivitiesModal
        isOpen={showEditActivities}
        onClose={() => setShowEditActivities(false)}
        combination={combination}
        onUpdate={handleActivitiesUpdate}
      />
    </motion.div>
  );
});

OutfitCard.displayName = 'OutfitCard';

const Favorites = () => {
  const { toast } = useToast();
  const [likedOutfits, setLikedOutfits] = useState([]);
  const [filteredOutfits, setFilteredOutfits] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState('All');
  const [isLoading, setIsLoading] = useState(true);
  const [availableActivities, setAvailableActivities] = useState(['All']);
  const [showDemo, setShowDemo] = useState(false);
  const { runTour, steps, startTour, config, pushToDataLayer } = useStartTour('favorites');

  const fetchLikedOutfits = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await client.get('/api/favorite-outfits/');
      setLikedOutfits(response.data);
      setFilteredOutfits(response.data);

      // Extract unique activities from favorite outfits
      const uniqueActivities = new Set(['All']);
      response.data.forEach(outfit => {
        outfit.activities?.forEach(activity => {
          uniqueActivities.add(activity);
        });
      });
      setAvailableActivities(Array.from(uniqueActivities));
    } catch (error) {
      console.error('Failed to fetch liked outfits:', error);
      toast({
        title: "Error",
        description: "Failed to fetch liked outfits",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    const hasSeenTour = localStorage.getItem('hasSeenFavoritesTour');
    if (!hasSeenTour) {
      startTour();
      setShowDemo(true);
      // Set demo activities
      setAvailableActivities(['All', ...demoFavoriteOutfit.activities]);
      localStorage.setItem('hasSeenFavoritesTour', 'false');
    }
  }, [startTour]);

  const handleJoyrideCallback = (data) => {
    const { action, index, status, step, type } = data;

    // Track tour interactions
    if (type === 'step:after' || type === 'tour:end' || type === 'tour:start') {
      pushToDataLayer(
        action,
        status,
        index + 1,
        step?.target || 'body'
      );
    }

    if (status === 'finished' || status === 'skipped') {
      localStorage.setItem('hasSeenFavoritesTour', 'true');
    }
  };

  const handleUnlike = useCallback(async (favoriteId) => {
    try {
      await client.delete(`/api/favorite-outfits/${favoriteId}/`);
      fetchLikedOutfits();
      toast({
        title: "Success",
        description: "Outfit removed from favorites",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to unlike outfit",
        variant: "destructive",
      });
    }
  }, [fetchLikedOutfits, toast]);

  const handleActivitySelect = useCallback((activity) => {
    setSelectedActivity(activity);
    if (activity === 'All') {
      setFilteredOutfits(likedOutfits);
    } else {
      setFilteredOutfits(
        likedOutfits.filter(outfit => 
          outfit.activities?.includes(activity)
        )
      );
    }
  }, [likedOutfits]);

  useEffect(() => {
    fetchLikedOutfits();
  }, [fetchLikedOutfits]);

  return (
    <div className="container mx-auto px-4 py-8">
      <Joyride
        {...config}
        run={runTour}
        steps={steps}
        callback={handleJoyrideCallback}
      />
      <h1 className="text-2xl font-bold mb-6">Favorite Outfits</h1>
      
      <div className="activity-filter">
        <ActivityFilter
          activities={availableActivities}
          selectedActivity={selectedActivity}
          onSelect={handleActivitySelect}
        />
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500"></div>
        </div>
      ) : (showDemo || filteredOutfits.length > 0) ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {showDemo && (
            <div className="favorite-card ">
              <OutfitCard
                combination={demoFavoriteOutfit}
                onUnlike={() => setShowDemo(false)}
              />
            </div>
          )}
          {filteredOutfits.map((combination) => (
            <OutfitCard
              key={combination.id}
              combination={combination}
              onUnlike={handleUnlike}
            />
          ))}
        </div>
      ) : (
        <div className="text-center py-12">
          <p className="text-gray-500 text-lg">You don't have any favorites yet!</p>
          <Link to="/generate" className="text-blue-500"
          id="favorites-generate-outfits-link">Generate outfits →</Link>
        </div>
      )}
    </div>
  );
};

export default memo(Favorites); 